<template>
  <v-card>
    <v-row v-if="idlist">
      <v-col cols="12">
        <div class="d-flex">
          <v-breadcrumbs :items="itemsBreadcrumbs" divider="/"></v-breadcrumbs>

          <v-tooltip v-if="statusChannelsBanner && bannerAlertText" bottom color="warning">
            <template #activator="{ on, attrs }">
              <v-icon v-bind="attrs" color="warning" v-on="on">
                {{ icons.mdiAlert }}
              </v-icon>
            </template>
            <span>{{ bannerAlertText }}</span>
          </v-tooltip>
        </div>
      </v-col>
    </v-row>
    <v-card-text>
      <v-row dense class="mb-0 pb-0">
        <v-col cols="12" :md="this.$i18n.locale === 'en' ? '2' : '1'">
          <v-text-field
            v-model="filter"
            :append-icon="icons.mdiMagnify"
            label="Search"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-select
            v-if="tab === 1"
            v-model="selectFilter"
            :disabled="itemsFilter.length === 0"
            :items="itemsFilter"
            item-text="label"
            dense
            clearable
            label="Filter"
            outlined
            @change="filterCode"
          ></v-select>
        </v-col>
        <v-spacer></v-spacer>
        <!-- <v-col v-if="idlist" cols="12" md="auto">
          <v-btn
            v-if="!isConfig"
            block
            color="primary"
            dense
            @click="
              $router.push({
                name: 'contacts-list-create-eform',
                params: {
                  idlist: idlist,
                },
              })
            "
          >
            <v-icon dark left>
              {{ icons.mdiPlusCircle }}
            </v-icon>
            {{ $t('add') }} {{ $t('E-form') }}
          </v-btn>
        </v-col> -->
        <v-col v-if="idlist" cols="12" md="auto">
          <v-btn v-if="!isConfig" block color="primary" dense @click="openConfig2">
            <v-icon dark left>
              {{ icons.mdiCog }}
            </v-icon>
            {{ $t('contacts.config') }}
          </v-btn>
        </v-col>
        <v-col v-if="idlist" cols="12" md="auto">
          <v-btn block color="primary" dense @click="importContact">
            <v-icon dark left>
              {{ icons.mdiImport }}
            </v-icon>
            {{ $t('import') }}
          </v-btn>
        </v-col>
        <v-col cols="12" md="auto">
          <v-btn block color="primary" dense>
            <v-icon dark left>
              {{ icons.mdiExport }}
            </v-icon>
            <vue-excel-xlsx
              :data="dataContact"
              :columns="columnsExport"
              :file-name="'DataContact'"
              :file-type="'xlsx'"
              :sheet-name="'DataContact'"
              class="primary-text text-uppercase"
            >
              {{ $t('exportToCsv') }}
            </vue-excel-xlsx>
          </v-btn>
        </v-col>
        <v-col cols="12" md="auto">
          <v-btn block color="primary" dense @click="exportVcf">
            <v-icon dark left>
              {{ icons.mdiExport }}
            </v-icon>
            {{ $t('exportToVcf') }}
          </v-btn>
        </v-col>
        <v-col v-if="idlist" cols="12" md="auto">
          <v-btn block color="primary" dense @click="openNewContact">
            <v-icon dark left>
              {{ icons.mdiAccountPlus }}
            </v-icon>
            {{ $t('add') }}
          </v-btn>
        </v-col>
      </v-row>
      <!-- actions -->
      <!-- <v-row
        dense
        class="mt-0 pt-0"
      >
        <v-col
          cols="12"
          md="auto"
        >
          <v-select
            v-model="selectedAction"
            label="Actions"
            single-line
            outlined
            item-text="label"
            dense
            :items="actions"
            hide-details
            :disabled="Boolean(!selectedTableData.length)"
            style="width: 200px"
            @change="selectAction"
          ></v-select>
        </v-col>
      </v-row> -->
    </v-card-text>
    <!-- table -->

    <v-tabs v-model="tab" show-arrows>
      <v-tab v-for="tab in tabs" :key="tab.title">
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>
    <!-- <pre>{{headerAllContactAnswerInSummary}}</pre> -->
    <!-- <pre>{{ columnsShow }}</pre> -->
    <!-- <pre>{{dataContact}}</pre> -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-data-table
          v-model="selectedTableData"
          item-key="_uid"
          :headers="columnsShow"
          :items="dataContact"
          :options.sync="options"
          :loading="loading"
          show-select
          class="text-no-wrap"
          :footer-props="{
            'items-per-page-text': $t('rowsPerPageLabel'),
            'items-per-page-options': [5, 10, 25, 50, 75, 100],
          }"
        >
          <template #[`item.action`]="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn v-bind="attrs" dense icon v-on="on" @click="openEditContact(item)">
                  <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                </v-btn>
              </template>
              <span>{{ `${$t('edit')} ${$t('history.contact')}` }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn v-bind="attrs" dense icon v-on="on" @click.stop="deleteContact(item)">
                  <v-icon>
                    {{ idlist ? icons.mdiDeleteOutline : item.archived !== 1 ? icons.mdiArchive : icons.mdiPackageUp }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ `${$t('delete')} ${$t('history.contact')}` }}</span>
            </v-tooltip>
          </template>
          <template #[`item.created_at`]="{ item }">
            {{ item.created_at !== undefined ? dateFormater(new Date(item.created_at)) : '' }}
          </template>
          <template #[`item.updated_at`]="{ item }">
            {{ item.updated_at !== undefined ? dateFormater(new Date(item.updated_at)) : '' }}
          </template>
          <template #[`item.name`]="{ item }">
            {{ item.name ? item.name : formatPhoneNumber(item.phone_number) }}
          </template>
          <!-- <template #[`item.nickname`]="{ item }">
            {{ item.nickname ? item.nickname : item.name }}
          </template> -->
          <!-- <template #[`item.type`]="{ item }">
            {{ item.type ? item.type : !item.has_wa ? 'Phone number' : 'Whatsapp' }}
          </template> -->
          <template #[`item.favourite`]="{ item }">
            <v-btn color="warning" icon @click="editFavourite(item)">
              <v-icon v-if="item.favourite">
                {{ icons.mdiStar }}
              </v-icon>
              <v-icon v-else>
                {{ icons.mdiStarOutline }}
              </v-icon>
            </v-btn>
          </template>
          <template #[`item.last_reply`]="{ item }">
            {{
              item.last_reply !== undefined && item.last_reply !== 0 && item.last_reply !== ''
                ? dateFormater(new Date(item.last_reply * 1000))
                : ''
            }}
          </template>
          <template #[`item.notes`]="{ item }">
            <v-btn
              v-if="item.notes && item.notes.length > 0"
              block
              color="primary"
              dense
              @click="openNotes(item.notes)"
            >
              {{ item.notes.length + ' Notes' }}
            </v-btn>
          </template>
          <template #[`item.answerData`]="{ item }">
            <v-btn color="primary" @click="seeAnswerData(item.answerData ? item.answerData : [])">
              <v-icon>
                {{ icons.mdiEyeOutline }}
              </v-icon>
            </v-btn>
          </template>
          <template #[`item.data_verification`]="{ item }">
            <v-btn color="primary" small @click="seeDataVerification(item)">
              <v-icon>
                {{ icons.mdiEyeOutline }}
              </v-icon>
            </v-btn>
          </template>
          <template #[`item.action_verify`]="{ item }">
            <div v-if="item.photoIdCard.hasOwnProperty('photoId') && item.kyc_status === 'Unverified'">
              <v-btn
                elevation="2"
                small
                color="primary"
                class="mr-2"
                @click="verifyDataVerification(item.phone_number)"
              >
                Verify
              </v-btn>
              <v-btn
                elevation="2"
                small
                color="error"
                @click="
                  seeRejectedDialog = true
                  currentPhoneNumber = item.phone_number
                "
              >
                Reject
              </v-btn>
            </div>
            <div v-else>
              <v-btn elevation="2" small color="primary" class="mr-2" disabled> Verify </v-btn>
              <v-btn elevation="2" small color="error" disabled> Reject </v-btn>
            </div>
          </template>
          <template #[`item.last_order_time`]="{ item }">
            {{ item.last_order_time !== undefined ? dateFormater(new Date(item.last_order_time)) : '' }}
          </template>

          <template #[`item.total_order`]="{ item }">
            {{ item.total_order !== undefined ? `Rp. ${item.total_order.toLocaleString('id')}` : '' }}
          </template>

          <template #[`item.phone_number`]="{ item }">
            <div class="d-flex align-center" style="cursor: pointer" @click="gotoInbox(item)">
              <v-avatar size="40">
                <v-img :lazy-src="defaultProfPic" :src="getProfPic(item.profile_picture, item)" height="40" width="40">
                </v-img>
              </v-avatar>
              <div class="d-flex flex-column ms-3">
                <span
                  style="text-align: left"
                  class="d-block font-weight-semibold text-truncate text--primary phone-number"
                  >{{ item.name ? maxTextLength(item.name) : formatPhoneNumber(item.phone_number) }}</span
                >
                <span style="text-align: left" class="text-xs">{{
                  item.phone_number.length > 15 ? 'Group Chat' : item.phone_number
                }}</span>
              </div>
            </div>
          </template>
          <template slot="no-data">
            <v-row align="center" class="py-16 padding-hero">
              <v-col class="text-center" lg="6" md="6" sm="12" cols="12">
                <div class="text-start hero-landing">
                  <h1 class="hero">
                    {{ $t('contacts.importContact') }}
                  </h1>
                  <div class="hero-subtitle hide-lottie">
                    <span class="text--primary">{{ $t('contacts.importContactSub') }}</span>
                  </div>
                  <div class="justify-center try-btn mt-3">
                    <v-btn elevation="2" class="button-goLogin" color="primary" @click="importContact">
                      <span style="color: white">{{ $t('import') }}</span>

                      <v-icon color="white">
                        {{ icons.mdiChevronRight }}
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-col>
              <v-col lg="6" md="6" sm="12" cols="12" class="hide-lottie">
                <div class="mx-auto" style="width: 50%">
                  <lottie-player
                    src="https://assets9.lottiefiles.com/private_files/lf30_3RMXDE.json"
                    background="transparent"
                    speed="1"
                    class="mx-auto"
                    loop
                    autoplay
                  ></lottie-player>
                </div>
              </v-col>
            </v-row>
          </template>
          <template #[`item.unreplied`]="{ item }">
            {{ item.unreplied === undefined ? '' : item.unreplied ? 'Unreplied' : 'Replied' }}
          </template>
          <template #[`item.last_interaction`]="{ item }">
            <v-avatar
              v-if="item.last_interaction"
              size="40px"
              color="primary"
              class="v-avatar-light-bg primary--text last-served-image"
            >
              <img :src="item.last_interaction.image" />
            </v-avatar>
            <div v-if="item.last_interaction" class="d-inline-flex flex-column justify-center ms-3">
              <span class="text--primary font-weight-semibold mb-n1 last-served">
                {{ item.last_interaction.email }}
              </span>
              <small class="text--disabled text-capitalize">{{ item.last_interaction.name }}</small>
            </div>
          </template>
          <template #[`item.assign_to`]="{ item }">
            <div v-if="user.role !== 'Admin' || user.role === 'Member'">
              <!-- <v-avatar
            v-if="item.assign_to"
            size="40px"
            color="primary"
            class="v-avatar-light-bg primary--text last-served-image"
          >
            <img :src="item.assign_to.image" />
          </v-avatar> -->
              <div v-if="item.assign_to" class="d-inline-flex flex-column justify-center ms-3">
                <div class="text--primary font-weight-semibold mb-n1 last-served">
                  {{ item.assign_to.email }}
                </div>
                <small class="text--disabled text-capitalize">{{ item.assign_to.name }}</small>
              </div>
            </div>
            <v-menu v-if="user.role === 'Admin'">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <!-- <v-avatar
                v-if="item.assign_to"
                size="40px"
                color="primary"
                class="v-avatar-light-bg primary--text last-served-image"
              >
                <img :src="item.assign_to.image" />
              </v-avatar> -->
                  <div v-if="item.assign_to" class="d-inline-flex flex-column justify-center ms-3">
                    <div class="text--primary font-weight-semibold mb-n1 last-served">
                      {{ item.assign_to.email }}
                    </div>
                    <small class="text--disabled text-capitalize">{{ item.assign_to.name }}</small>
                  </div>
                  <v-icon>{{ icons.mdiChevronDown }}</v-icon>
                </div>
              </template>
              <div class="mx-auto my-auto">
                <v-list dense>
                  <v-list-item-group v-model="selectedOperator" color="primary">
                    <v-list-item v-for="operator in userList" :key="operator._id" @change="setOperator(operator, item)">
                      <v-list-item-icon>
                        <v-icon v-text="icons.mdiAccount"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-text="operator.email">
                          {{ selectedOperator }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
            </v-menu>

            <!-- <v-select
          v-model="selectedOperator"
          :items="userList"
          item-text="email"
          item-value="email"
          label="Select Operator"
          dense
          hide-details
        ></v-select> -->
            <!-- <v-avatar
          v-if="item.last_interaction"
          size="40px"
          color="primary"
          class="v-avatar-light-bg primary--text last-served-image"
        >
          <img :src="item.last_interaction.image" />
        </v-avatar>
        <div
          v-if="item.last_interaction"
          class="d-inline-flex flex-column justify-center ms-3"
        >
          <span class="text--primary font-weight-semibold mb-n1 last-served">
            {{ item.last_interaction.email }}
          </span>
          <small class="text--disabled text-capitalize">{{ item.last_interaction.name }}</small>
        </div> -->
          </template>
          <template #[`item.level_engagement`]="{ item }">
            {{ getEngagementName(item.level_engagement) }}
          </template>
          <template #[`item.last_message_status`]="{ item }">
            {{ getMessageStatusResult(item.last_message_status) }}
          </template>
          <!-- <template #[`item.subscribe_broadcast`]="{ item }">
        {{ item.subscribe_broadcast === false ? 'Unsubscribe' : 'Subscribe' }}
      </template> -->
        </v-data-table>
      </v-tab-item>
      <!-- chartnya -->
      <v-tab-item>
        <div v-if="loading === false">
          <v-card-subtitle class="mb-8 mt-10">
            <span class="font-weight-semibold text--primary me-1">{{ $t('broadcasts.date') }} :</span>
            <span>{{ dates.join(' - ') }}</span>

            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              bottom
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on }">
                <v-btn icon small v-on="on">
                  <v-icon>
                    {{ icons.mdiCalendarMonth }}
                  </v-icon>
                </v-btn>
              </template>
              <v-date-picker v-if="menu === true" v-model="dates" range no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="#ffeeee" @click="menu = false">
                  <span class="error--text">{{ $t('cancel') }}</span>
                </v-btn>
                <v-btn text color="success" @click="getStatisticChart(dates)"> OK </v-btn>
              </v-date-picker>
            </v-menu>
          </v-card-subtitle>

          <vue-apex-charts
            type="area"
            height="300"
            :options="chartCreatedAt"
            :series="userDataCreated"
          ></vue-apex-charts>
        </div>
        <v-row class="mb-3 my-3">
          <v-col cols="12" md="3"> </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" lg="3" md="3" sm="3" xs="3">
            <div class="d-flex">
              <span class="mt-5 me-3">Chat</span> <v-switch v-model="uniqData"></v-switch>
              <span class="mt-5 ms-3">Contact</span>
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-3 mb-3">
          <v-col cols="12">
            <v-card-title class="align-start text-h6 font-weight-semibold">
              <span>New {{ uniqData ? 'Contact' : 'Chat' }}</span>
            </v-card-title>
            <vue-apex-charts
              v-if="!uniqData"
              type="area"
              height="300"
              :options="chartCreatedAt2"
              :series="messageStatisticNew"
            ></vue-apex-charts>
            <vue-apex-charts
              v-if="uniqData"
              type="area"
              height="300"
              :options="chartCreatedAt2"
              :series="messageStatisticUniq"
            ></vue-apex-charts>
          </v-col>
        </v-row>
        <v-row class="mt-3 mb-3">
          <v-col cols="12">
            <v-card-title class="align-start text-h6 font-weight-semibold">
              <span>Incoming {{ uniqData ? 'Contact' : 'Chat' }}</span>
            </v-card-title>
            <vue-apex-charts
              v-if="!uniqData"
              type="area"
              height="300"
              :options="chartCreatedAt2"
              :series="messageStatisticIncoming"
            ></vue-apex-charts>
            <vue-apex-charts
              v-if="uniqData"
              type="area"
              height="300"
              :options="chartCreatedAt2"
              :series="messageStatisticIncomingUniq"
            ></vue-apex-charts>
          </v-col>
        </v-row>
        <v-row class="mt-3 mb-3">
          <v-col cols="12">
            <v-card-title class="align-start text-h6 font-weight-semibold">
              <span>Outgoing {{ uniqData ? 'Contact' : 'Chat' }}</span>
            </v-card-title>
            <vue-apex-charts
              v-if="!uniqData"
              type="area"
              height="300"
              :options="chartCreatedAt2"
              :series="messageStatisticOutcoming"
            ></vue-apex-charts>
            <vue-apex-charts
              v-if="uniqData"
              type="area"
              height="300"
              :options="chartCreatedAt2"
              :series="messageStatisticOutcomingUniq"
            ></vue-apex-charts>
          </v-col>
        </v-row>
        <v-row v-if="loading === false">
          <v-col v-for="chart in blockColumns" :key="chart" cols="4">
            <v-card class="ms-3 my-3">
              <v-card-title>
                {{ chart }}
              </v-card-title>
              <vue-apex-charts
                type="donut"
                height="350"
                :options="dataChart[chart].chartOptions"
                :series="dataChart[chart].series"
              />
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8"> </v-col>
          <v-col cols="12" md="3">
            <v-btn v-if="!loading" block color="primary" dense>
              <v-icon dark left>
                {{ icons.mdiExport }}
              </v-icon>
              <vue-excel-xlsx
                :data="allContactAnswerInSummary"
                :columns="headerAllContactAnswerInSummary"
                :file-name="'SummaryCustomer'"
                :file-type="'xlsx'"
                :sheet-name="'SummaryCustomer'"
                class="primary-text text-uppercase"
              >
                {{ $t('exportToCsv') }}
              </vue-excel-xlsx>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headerAllContactAnswerInSummary"
              :items="allContactAnswerInSummary"
              :options.sync="options"
              :loading="loading"
              class="text-no-wrap"
              :footer-props="{
                'items-per-page-text': $t('rowsPerPageLabel'),
                'items-per-page-options': [5, 10, 25, 50, 75, 100],
              }"
            ></v-data-table>
          </v-col>
        </v-row>
        <!-- <pre>{{dataChart2}}</pre>
          <v-row>
          <v-col
            v-for="(random,index) in blockColumns2"
            :key="index"
            cols="4"
          >
            <v-card class="ms-3 my-3">
              <v-card-title>
                {{random }}
              </v-card-title>
              <vue-apex-charts
                type="donut"
                height="350"
                :options="dataChart2[random].chartOptions"
                :series="dataChart2[random].series"
              />
            </v-card>
          </v-col>
        </v-row> -->

        <!-- <account-settings-invoice></account-settings-invoice> -->
      </v-tab-item>
    </v-tabs-items>

    <!-- dialog config -->
    <v-dialog v-model="dialogNotes" width="600px">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">
            {{ $t('notes') }}
          </h3>
          <v-spacer></v-spacer>
          <v-btn color="#ffeeee" class="mr-3" @click="dialogNotes = false">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
        </v-card-actions>
        <v-divider />

        <v-card-text>
          <div v-for="(note, index) of notes" :key="index">
            <v-row>
              <v-col cols="12" md="auto">
                {{ 1 + index + '. ' }}
              </v-col>
              <v-col cols="12" md="10">
                <span v-html="urlify(note.name)"></span>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- dialog config -->
    <!-- <v-dialog v-model="dialogConfig" width="600px" persistent>
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">
            {{ $t('contacts.config') }}
          </h3>
          <v-spacer></v-spacer>

        </v-card-actions>
        <v-divider />

        <v-card-text id="yourScrollElementId" class="height-dialog">
          <v-row>
            <v-col cols="12" lg="12">
              <table class="billing-address-table">
                <tr>
                  <td style="width: 40px"></td>
                  <td style="width: 200px">
                    <p class="text-no-wrap text--primary font-weight-medium">
                      {{ $t('contacts.columnName') }}
                    </p>
                  </td>
                  <td style="width: 175px">
                    <p class="text-no-wrap text--primary font-weight-medium">
                      {{ $t('contacts.defaultValue') }}
                    </p>
                  </td>
                  <td v-if="surveyConfig.status" class="me-2" style="width: 15px; padding-right: 15px">
                    <p class="text-no-wrap text--primary font-weight-medium">
                      Show Field
                    </p>
                  </td>
                  <td style="width: 75px">
                    <p class="text-no-wrap text--primary font-weight-medium">
                      {{ $t('contacts.action') }}
                    </p>
                  </td>
                </tr>
                <draggable
                  v-model="listDraggable"
                  handle=".handle"
                  tag="tbody"
                  @start="drag = true"
                  @end="drag = false"
                >
                  <tr v-for="(data, index) of listDraggable" :key="index">
                    <td v-if="data.selected === undefined" style="width: 40px" class="handle cursor-pointer">
                      <v-icon large class="mt-4" color="green darken-2">
                        {{ icons.mdiDragVertical }}
                      </v-icon>
                    </td>
                    <td v-else style="width: 40px"></td>
                    <td style="width: 100px">
                      <v-text-field
                        v-model="data.text"
                        class="mr-4 mt-4"
                        disa
                        single-line
                        hide-details
                        dense
                        outlined
                        solo
                        :disabled="!data.editable || data.value === 'name'"
                      ></v-text-field>
                    </td>
                    <td style="width: 200px">
                      <v-text-field
                        v-model="data.defaultValue"
                        class="mr-4 mt-4"
                        disa
                        single-line
                        hide-details
                        dense
                        outlined
                        solo
                        :disabled="!data.editable || data.value === 'name'"
                      ></v-text-field>
                    </td>
                    <td
                      v-if="surveyConfig.status && data.selected !== undefined"
                      class="d-flex mx-auto justify-center"
                      style="width: 15px"
                    >
                      <v-checkbox v-model="data.selected" class="mx-auto" color="primary"></v-checkbox>
                    </td>
                    <td style="width: 75px">
                      <v-btn v-if="data.deleteable" class="mt-4" icon @click.stop="deleteColumn(data, index)">
                        <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </draggable>
              </table>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>
      </v-card>
    </v-dialog> -->

    <!-- dialog add contact -->
    <v-dialog v-model="dialogContact" width="400px">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">
            {{ (isEdit ? $t('edit') : $t('add')) + ' ' + $t('contacts.contact') }}
          </h3>
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-divider />

        <v-card-text class="height-dialog">
          <v-snackbar v-model="showSnackbarErr" color="error" :timeout="2000" :top="true" style="z-index: 5 !important">
            {{ errMessage }}
            <template #action="{ attrs }">
              <v-btn text v-bind="attrs" @click="closeShowSnackbarErr()">
                {{ $t('close') }}
              </v-btn>
            </template>
          </v-snackbar>
          <v-form>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="model.contactName"
                  label="Contact Name"
                  outlined
                  dense
                  placeholder="Contact Name"
                  hide-details
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="12" md="6">
                <v-text-field
                  v-model="model.nickname"
                  label="Nickname"
                  outlined
                  dense
                  placeholder="Nickname"
                  hide-details
                ></v-text-field>
              </v-col> -->
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="model.phoneNumber"
                  label="Phone Number"
                  outlined
                  dense
                  type="number"
                  min="0"
                  :disabled="isEdit"
                  placeholder="Phone Number"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-for="(data, index) of model.data" :key="index" cols="12" md="12">
                <v-text-field
                  v-model="data.attributeValue"
                  :label="data.attributeLabel"
                  outlined
                  dense
                  :placeholder="data.attributeLabel"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider class="" />
        <v-card-actions class="">
          <v-spacer></v-spacer>
          <v-btn color="#ffeeee" class="mr-3" @click="dialogContact = false">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn color="success" class="" @click="saveContact">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog alert -->

    <v-dialog v-model="dialogAlert" width="500">
      <v-card>
        <v-card-title color="error">
          {{ $t('warning') }}
        </v-card-title>

        <v-card-text>
          {{ alertText }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="dialogAlert = false">
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog confirmation -->
    <confirm ref="confirm"></confirm>

    <!-- dialog select label -->
    <selectLabel ref="selectLabel"></selectLabel>

    <!-- dialog select list -->
    <dialogSelectList ref="dialogSelectList"></dialogSelectList>

    <!-- dialog select list -->
    <dialogSelectOperator ref="dialogSelectOperator"></dialogSelectOperator>

    <!-- dialog select label -->
    <dialogImport ref="dialogImport"></dialogImport>

    <!-- dialog select label -->
    <dialogAddListContact ref="dialogAddListContact"></dialogAddListContact>

    <!-- dialog batch edit -->
    <dialogBatchEdit ref="dialogBatchEdit"></dialogBatchEdit>

    <!-- addBroadcast Dialog -->
    <v-dialog v-model="addBroadcastDialog" width="900" scrollable>
      <template v-slot:default="">
        <v-card elevation="0">
          <v-toolbar color="primary">
            <div class="text-h5 white--text">
              {{ $t('broadcasts.createBroadcast') }}
            </div>
            <v-spacer></v-spacer>
            <v-btn class="pa-8" icon @click="closeDialog(false)">
              <v-icon x-large color="white">
                {{ icons.mdiCloseCircle }}
              </v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <add-broadcast
              :key="newBroadcast"
              :contacts-from-wa="selectedTableData"
              @close-dialog="closeDialog(true)"
            ></add-broadcast>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>

    <!-- Dialog answers -->
    <v-dialog v-model="seeAnswerDialog" width="600px">
      <v-card>
        <v-card-title>
          <h4>Answers</h4>
        </v-card-title>

        <v-divider />

        <v-card-text>
          <v-card>
            <v-data-table
              :headers="questions"
              :items="answers"
              :loading="loading"
              class="text-no-wrap"
              :footer-props="{
                'items-per-page-text': $t('rowsPerPageLabel'),
                'items-per-page-options': [5, 10, 25, 50, 75, 100],
              }"
            />
            <!-- <template #[`item.answerData.`]="{ item }">
          </template> -->
            <!-- <v-row
              v-for="(question, i) in questions"
              :key="i + '-question'"
            >
              <v-col
                cols="12"
              >
                <h3>{{ question }}</h3>
              </v-col>
              <v-divider></v-divider>
              <v-col
                v-for="(answer, indexAnswer) in answers"
                :key="indexAnswer + '-answer'"
                cols="12"
              >
                <h5 v-if="answer[question]">
                  {{ answer[question] }}
                </h5>
                <h5 v-else>
                  Not Answered
                </h5>
              </v-col>
            </v-row> -->
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="seeDataVerificationDialog" width="600px">
      <v-card>
        <v-card-title>
          <h4>Photo Data Verification</h4>
        </v-card-title>
        <v-divider />
        <v-card-text v-if="hasReasonRejected">
          <h4 class="text-center my-3">Reason Rejected</h4>
          <v-textarea v-model="reasonRejectedPerUser" solo disabled></v-textarea>
        </v-card-text>
        <div v-if="hasUploadPhotoIdCard">
          <v-card-text>
            <h4 class="text-center my-3">Photo Id Card</h4>
            <div class="d-flex justify-center">
              <v-img max-height="200" max-width="300" :src="photoIdCard"></v-img>
            </div>
          </v-card-text>
          <v-card-text>
            <h4 class="text-center my-3">Self Photo & Id Card</h4>
            <div class="d-flex justify-center">
              <v-img max-height="200" max-width="300" :src="selfPhotoIdCard"></v-img>
            </div>
          </v-card-text>
        </div>
        <div v-else class="d-flex justify-center my-6">
          <h4>User hasn't uploaded photo yet.</h4>
        </div>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="#ffeeee" class="mr-3" @click="seeDataVerificationDialog = false">
            <span class="error--text">Close</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="seeRejectedDialog" width="600px">
      <v-card>
        <v-card-title>
          <h4>Reason Rejected</h4>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="hasReasonRejected">
          <v-textarea
            v-model="reasonRejected"
            solo
            name="reasonRejected"
            label="Input reason rejected here..."
          ></v-textarea>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            color="#ffeeee"
            class="mr-3"
            @click="
              seeRejectedDialog = false
              reasonRejected = ''
            "
          >
            <span class="error--text">Close</span>
          </v-btn>
          <v-btn color="#eeffee" class="mr-3" @click="rejectDataVerification">
            <span class="success--text">Reject</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="seeAlertMessage" width="500px">
      <v-card>
        <v-card-text>
          <h4>{{ alertMessage }}</h4>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- <v-dialog
      v-model="loading"
      hide-overlay
      width="320"
    >
      <v-card color="primary">
        <v-card-text class="pt-3 white--text">
          Preparing contact
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog> -->
    <dialog-config-form-builder ref="dialogConfigFormBuilder"></dialog-config-form-builder>
  </v-card>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import { TimeFormater } from '@/helpers'
import firestoreDbMixin from '@/mixins/firestoreDbMixin'
import {
  mdiAccount,
  mdiAccountPlus,
  mdiAlert,
  mdiArchive,
  mdiCalendar,
  mdiCalendarMonth,
  mdiChartBar,
  mdiChevronDown,
  mdiChevronRight,
  mdiCloseCircle,
  mdiCog,
  mdiContentSave,
  mdiDeleteOutline,
  mdiDragVertical,
  mdiExport,
  mdiEyeOutline,
  mdiFilter,
  mdiFilterOff,
  mdiImport,
  mdiMagnify,
  mdiPackageUp,
  mdiPencilOutline,
  mdiPlusCircle,
  mdiStar,
  mdiStarOutline,
  mdiTrashCan,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import _uniq from 'lodash/uniq'
import _uniqBy from 'lodash/uniqBy'
import PouchDB from 'pouchdb-browser'
import vCardsJS from 'vcards-js'
import VueApexCharts from 'vue-apexcharts'
import draggable from 'vuedraggable'
import contactMixin from '../../mixins/contactMixin'

// import custom
import eFormMixin from '../../mixins/eFormMixin'
import pouchDbMixin from '../../mixins/pouchDbMixin'
import utilsMixin from '../../mixins/utilsMixin'

import AddBroadcast from '../broadcast/AddBroadcast.vue'
import confirm from '../component/Confirm.vue'
import dialogAddListContact from '../component/DialogAddListContact.vue'
import dialogBatchEdit from '../component/DialogBatchEdit.vue'
import DialogConfigFormBuilder from '../component/DialogConfigFormBuilder.vue'
import dialogImport from '../component/DialogImport.vue'
import selectLabel from '../component/DialogSelectLabel.vue'
import dialogSelectList from '../component/DialogSelectList.vue'
import dialogSelectOperator from '../component/DialogSelectOperator.vue'

// eslint-disable-next-line import/no-unresolved
PouchDB.adapter('worker', require('worker-pouch'))

function wrapCsvValue(val, formatFn) {
  let formatted = formatFn !== void 0 ? formatFn(val) : val

  formatted = formatted === void 0 || formatted === null ? '' : String(formatted)

  formatted = formatted.split('"').join('""')

  /**
   * Excel accepts \n and \r in strings, but some other CSV parsers do not
   * Uncomment the next two lines to escape new lines
   */
  // .split('\n').join('\\n')
  // .split('\r').join('\\r')

  return `"${formatted}"`
}

export default {
  name: 'Contacts',
  components: {
    draggable,
    confirm,
    selectLabel,
    dialogSelectList,
    dialogSelectOperator,
    dialogImport,
    dialogAddListContact,
    AddBroadcast,
    dialogBatchEdit,
    DialogConfigFormBuilder,
    VueApexCharts,
  },
  mixins: [firestoreDbMixin, pouchDbMixin, contactMixin, eFormMixin, utilsMixin],
  data() {
    return {
      dataListChannel: [],

      // contacts: [],
      showSnackbarErr: false,
      errMessage: '',
      surveyConfig: { id: '', status: false },
      questions: [],
      rangeDates: [],
      answers: [],
      seeAnswerDialog: false,
      seeDataVerificationDialog: false,
      seeRejectedDialog: false,
      hasUploadPhotoIdCard: true,
      photoIdCard: '',
      selfPhotoIdCard: '',
      seeAlertMessage: false,
      alertMessage: '',
      currentPhoneNumber: '',
      reasonRejected: '',
      hasReasonRejected: false,
      reasonRejectedPerUser: '',
      userDataCreated: [{ data: [], name: 'Data Inserted' }],
      userDataCreatedShow: [],
      dates: [
        new Date(new Date().setDate(new Date().getDate() - 5)).toISOString().substring(0, 10),
        new Date().toISOString().substring(0, 10),
      ],
      chartCreatedAt: {},
      chartCreatedAt2: {},
      uniqData: false,
      messageStatistic: [],
      messageStatisticNew: [],
      messageStatisticUniq: [],
      messageStatisticIncoming: [],
      messageStatisticOutcoming: [],
      messageStatisticIncomingUniq: [],
      messageStatisticOutcomingUniq: [],
      countingAnswer: {},
      dataChart: {},
      dataChart2: {},
      donutChart: {
        series: [1, 1],
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: '"Inter", sans-serif',
          },
          colors: ['#ffe700', '#00d4bd'],
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },

          // plotOptions: {
          //   pie: {
          //     donut: {
          //       labels: {
          //         show: true,
          //         name: {
          //           fontSize: '2rem',
          //           fontFamily: '"Inter", sans-serif',
          //         },
          //         value: {
          //           fontSize: '1rem',
          //           fontFamily: '"Inter", sans-serif',
          //           formatter(val) {
          //             // eslint-disable-next-line radix
          //             return `${parseInt(val)}%`
          //           },
          //         },
          //         total: {
          //           show: true,
          //           fontSize: '1.5rem',
          //           label: 'Operational',
          //           formatter() {
          //             return '31%'
          //           },
          //         },
          //       },
          //     },
          //   },
          // },
          labels: ['Response', 'No Response'],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1.5rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
      tab: ref(''),
      menu: false,
      tabs: [{ title: 'Table' }, { title: 'Summary' }],
      today: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`,
      listContactValue: [],
      labelData: [],
      contactListSetting: null,
      currentList: null,
      couchAllContact: [],
      isRtl: '',
      icons: {
        mdiAlert,
        mdiCalendarMonth,
        mdiCog,
        mdiMagnify,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiPlusCircle,
        mdiExport,
        mdiChartBar,
        mdiEyeOutline,
        mdiDragVertical,
        mdiAccountPlus,
        mdiImport,
        mdiFilter,
        mdiFilterOff,
        mdiTrashCan,
        mdiCalendar,
        mdiContentSave,
        mdiCloseCircle,
        mdiArchive,
        mdiPackageUp,
        mdiStar,
        mdiStarOutline,
        mdiChevronDown,
        mdiAccount,
        mdiChevronRight,
      },
      itemsBreadcrumbs: [
        {
          text: 'Contact',
          disabled: false,
          link: true,
          exact: true,
          to: {
            name: 'contacts-group-list',
          },
        },
      ],
      allContactAnswerInSummary: [],
      headerAllContactAnswerInSummary: [
        { text: 'Contact Name', value: 'nameAtContactList', align: 'center' },
        { text: 'Phone Number', value: 'phone_number', align: 'center' },
      ],
      filter: '',
      selectFilter: null,
      showFilter: false,
      filterValue: [],
      filterValue2: [],
      filterOperator: [],
      selectedOperator: '',
      menu: [],
      menu2: [],
      itemFilterColumn: [
        '',
        'equals',
        'not equals',
        'contains',
        'not contains',
        'starts with',
        'finishes with',
        'is empty',
        'is not empty',
      ],
      itemFilterColumnDate: [
        '',
        'on',
        'not on',
        'after',
        'before',
        'between',
        'not between',
        'is empty',
        'is not empty',
      ],

      columnsSetting: null,
      settingFilterSetting: null,
      tableColumns: [],
      itemsFilter: [],

      // labelData: [],
      labelValue: [],
      labelSetting: [],

      contactListTable: [],
      contactListTableOrigin: [],
      totalContact: 0,
      options: {
        sortBy: ['last_message'],
        sortDesc: [true],
      },
      selectedTableData: [],
      loading: false,
      selectedAction: '',
      actions: [],

      // DIALOG CONFIG
      listDraggable: [],
      dialogConfig: false,

      // DIALOG ALERT
      alertText: false,
      dialogAlert: false,

      // DIALOG CONTACT
      isEdit: false,
      dialogContact: false,
      model: {
        contactName: '',
        nickname: '',
        phoneNumber: '',
        data: [],
        has_wa: '',
      },

      // DIALOG ADD FILTER
      filterName: '',

      // DIALOG BROADCAST
      addBroadcastDialog: false,
      newBroadcast: 1,

      // DIALOG NOTES
      dialogNotes: false,
      notes: [],

      // contactListTable: [],

      // Charts
      blockColumns: [],
      blockColumns2: [],
      countingAction: [],
      isConfig: true,
      listInstances: [],
      listInstance: [],
    }
  },
  computed: {
    // listInstances() {
    //   const inst = this.$store.getters['auth/getListInstance']
    //   if (inst) {
    //     return inst.value
    //   }

    //   return []
    // },
    bannerAlertText() {
      return this.$store.getters['inbox/getBannerAlertText']
    },
    statusChannelsBanner() {
      return this.$store.getters['global/getStatusChannels']
    },
    // listInstance() {
    //   return this.$store.getters['auth/getListInstance'].value.filter(x => x.status == 1)
    // },

    // dateRangeText() {
    //   let data = JSON.parse(JSON.stringify(this.dates))
    //   // data.join(' ~ ')
    //   return `${data[0]} ~ ${data[1]}`
    // },
    defaultProfPic() {
      return `${process.env.VUE_APP_WA_API_URL}ppic/@whatsapp.net/2414214242414/a.jpg`
    },
    allSettings() {
      return this.$store.getters['global/getSettings']
    },

    contactVuex() {
      if (this.idlist) {
        return this.$store.getters['global/getContacts'].filter(x => x._id.startsWith(this.idlist))
      }

      return this.$store.getters['global/getContacts'].filter(x => x._id === x.phone_number)
    },
    idlist() {
      return this.$route.params.idlist
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    userList() {
      return this.$store.getters['auth/getListUsers']
    },
    columnsAdditionalEdit() {
      const tableFilter = this.selectionSort(this.tableColumns.filter(x => x.deleteable || x.value === 'name'))

      return tableFilter
    },
    columnsEditable() {
      const tableFilter = this.selectionSort(
        this.tableColumns.filter(
          x => x.value !== 'id' && x.value !== 'label' && x.value !== 'phone_number' && x.value !== 'status',
        ),
      )

      return tableFilter
    },
    columnsExport() {
      let table = this.tableColumns.filter(x => x.show && x.value !== 'status')
      const tableFilter = this.selectionSort(table)
      table = tableFilter

      return table
    },
    columnsShow() {
      let table = JSON.parse(JSON.stringify(this.tableColumns)).filter(
        x =>
          x.show &&
          x.value !== 'source' &&
          x.value !== 'has_wa' &&
          x.value !== 'last_interaction' &&
          x.value !== 'unreplied',
      )
      const tableFilter = this.selectionSort(table)
      table = tableFilter
      table.unshift({
        name: '',
        align: 'center',
        label: '',
        field: '',
        value: 'action',
        text: '',
        sortable: true,
        type: 'text',
        show: false,
        showable: true,
        formatDate: '',
        deleteable: true,
        isRequired: false,
        defaultValue: '',
        editable: true,
        order_number: this.tableColumns.length + 1,
      })

      if (this.idlist === '0kftlo') {
        table.push({
          name: 'email',
          align: 'center',
          label: 'Email',
          field: 'email',
          value: 'email',
          text: 'Email',
          sortable: true,
          type: 'text',
          show: true,
          showable: true,
          formatDate: '',
          deleteable: false,
          isRequired: false,
          defaultValue: '',
          editable: false,
          order_number: 0,
        })
      }

      if (!table.find(column => column.name === 'created_at')) {
        table.push({
          name: 'created_at',
          align: 'center',
          label: 'Created At',
          field: 'created_at',
          value: 'created_at',
          text: 'Created At',
          sortable: true,
          type: 'text',
          show: true,
          showable: true,
          formatDate: '',
          deleteable: false,
          isRequired: false,
          defaultValue: '',
          editable: false,
          order_number: this.tableColumns.length + 1,
        })
      }
      if (!table.find(column => column.name === 'updated_at')) {
        table.push({
          name: 'updated_at',
          align: 'center',
          label: 'Updated At',
          field: 'updated_at',
          value: 'updated_at',
          text: 'Updated At',
          sortable: true,
          type: 'text',
          show: true,
          showable: true,
          formatDate: '',
          deleteable: false,
          isRequired: false,
          defaultValue: '',
          editable: false,
          order_number: this.tableColumns.length + 1,
        })
      }

      table.forEach(item => {
        if (
          item.field != 'action' &&
          item.field != 'name' &&
          item.field != 'phone_number' &&
          item.field != 'phone_number_show'
        ) {
          item.filterable = false
        } else {
          item.filterable = true
        }

        if (item.text === 'Name') {
          item.text = this.$t('name')
          item.value = item.name
        } else if (item.text === 'Phone Number') {
          item.text = this.$t('phoneNumber')
          item.name = 'phone_number'
          item.value = 'phone_number'
        } else if (item.text === 'Last Reply') {
          item.text = this.$t('lastReply')
        } else if (item.text === 'Notes') {
          item.text = this.$t('notes')
        } else if (item.text === 'Last Message') {
          item.text = this.$t('lastMessage')
        } else if (item.text === 'Favourite') {
          item.text = this.$t('favourite')
        } else if (item.text === 'Interaction') {
          item.text = this.$t('interaction')
        } else if (item.text === 'Need Reply') {
          item.text = this.$t('needReply')
        } else if (item.text === 'Assign To') {
          item.text = this.$t('assignTo')
        } else if (item.text === 'Message Status') {
          item.text = this.$t('messageStatus')
        }
      })

      const columnTableHappyhome = table.filter(
        column => column.name === 'phone_number' || column.name === 'name' || column.name === 'email',

        // column.name === 'answerData',
      )

      columnTableHappyhome.push({
        name: 'Kyc Status',
        align: 'center',
        label: 'Kyc Status',
        field: 'kyc_status',
        value: 'kyc_status',
        text: 'Kyc Status',
        sortable: true,
        type: 'text',
        show: true,
        showable: true,
        formatDate: '',
        deleteable: false,
        isRequired: false,
        defaultValue: '',
        editable: false,
        order_number: 0,
      })
      columnTableHappyhome.push({
        name: 'Data Verification',
        align: 'center',
        label: 'Data Verification',
        field: 'data_verification',
        value: 'data_verification',
        text: 'Data Verification',
        sortable: true,
        type: 'text',
        show: true,
        showable: true,
        formatDate: '',
        deleteable: false,
        isRequired: false,
        defaultValue: '',
        editable: false,
        order_number: 0,
      })
      columnTableHappyhome.push({
        name: 'Action Verify',
        align: 'center',
        label: 'Action Verify',
        field: 'action_verify',
        value: 'action_verify',
        text: 'Action Verify',
        sortable: true,
        type: 'text',
        show: true,
        showable: true,
        formatDate: '',
        deleteable: false,
        isRequired: false,
        defaultValue: '',
        editable: false,
        order_number: 0,
      })
      columnTableHappyhome.push({
        name: 'created_at',
        align: 'center',
        label: 'Created At',
        field: 'created_at',
        value: 'created_at',
        text: 'Created At',
        sortable: true,
        type: 'text',
        show: true,
        showable: true,
        formatDate: '',
        deleteable: false,
        isRequired: false,
        defaultValue: '',
        editable: false,
        order_number: this.tableColumns.length + 1,
      })
      columnTableHappyhome.push({
        name: 'updated_at',
        align: 'center',
        label: 'Updated At',
        field: 'updated_at',
        value: 'updated_at',
        text: 'Updated At',
        sortable: true,
        type: 'text',
        show: true,
        showable: true,
        formatDate: '',
        deleteable: false,
        isRequired: false,
        defaultValue: '',
        editable: false,
        order_number: this.tableColumns.length + 1,
      })

      if (this.idlist === '0kftlo') {
        return columnTableHappyhome
      }

      return table
    },
    columnsAdditional() {
      return this.tableColumns.filter(x => x.deleteable).sort((a, b) => (a.order_number < b.order_number ? -1 : 0))
    },
    dataContact() {
      const finalData = this.contactListTable

      const filterData = finalData.filter(x => {
        let isFiltered = false
        x.created_at = this.dateFormater(new Date(x.created_at))
        x.updated_at = this.dateFormater(new Date(x.updated_at))

        if (this.filter !== '') {
          for (const field in x) {
            if (x[field] && x[field].toString().toLowerCase().includes(this.filter.toLowerCase())) {
              isFiltered = true
            }
            if ((field === 'last_reply' || field === 'last_message') && x[field] && !isFiltered) {
              var dateString = this.$moment(x[field] * 1000).format('DD-MM-YYYY HH:mm:ss')
              isFiltered = dateString.toString().toLowerCase().includes(this.filter.toLowerCase())
            }
            if (field === 'unreplied' && !isFiltered) {
              var dateString = x[field] === undefined ? '' : x[field] ? 'Unreplied' : 'Replied'
              isFiltered = dateString.toString().toLowerCase().includes(this.filter.toLowerCase())
            }
            if (field === 'level_engagement' && !isFiltered) {
              var dateString = this.getEngagementName(x[field])
              isFiltered = dateString.toString().toLowerCase().includes(this.filter.toLowerCase())
            }
            if (field === 'assign_to' && !isFiltered) {
              const email = x[field] ? x[field].email : ''
              isFiltered = email.toString().toLowerCase().includes(this.filter.toLowerCase())
            }
          }
        } else {
          isFiltered = true
        }

        return isFiltered
      })

      //console.log(filterData)

      return filterData

      // }

      // return finalData
    },
    dataListContact2() {
      return this.contactListTable
    },
  },
  watch: {
    selectFilter() {
      this.actions = this.idlist
        ? [
            { label: this.$t('delete'), value: 'delete' },
            { label: this.$t('edit'), value: 'edit' },
            { label: this.$t('setLabel'), value: 'label' },
          ]
        : this.user.role === 'Admin'
        ? [
            {
              label:
                this.selectFilter && (this.selectFilter.name === 'Archived' || this.selectFilter.name === 'Closed')
                  ? this.$t('unarchived')
                  : this.$t('archive'),
              value: 'delete',
            },
            { label: this.$t('edit'), value: 'edit' },
            { label: this.$t('setLabel'), value: 'label' },
            { label: `${this.$t('send')} Broadcast`, value: 'send' },
            { label: `${this.$t('addTo')} Contact List`, value: 'add' },
            { label: this.$t('assignTo'), value: 'assign' },
          ]
        : [
            {
              label:
                this.selectFilter && (this.selectFilter.name === 'Archived' || this.selectFilter.name === 'Closed')
                  ? this.$t('unarchived')
                  : this.$t('archive'),
              value: 'delete',
            },
            { label: this.$t('edit'), value: 'edit' },
            { label: this.$t('setLabel'), value: 'label' },
            { label: `${this.$t('send')} Broadcast`, value: 'send' },
            { label: `${this.$t('addTo')} Contact List`, value: 'add' },
          ]
    },
    selectedTableData() {
      if (this.selectedTableData.length === 0) {
        this.selectedAction = ''
      }
    },
  },
  beforeDestroy() {
    this.$eventBus.$off(process.env.VUE_APP_EVENT_UPDATE_SETTINGS)

    // this.$eventBus.$off(process.env.VUE_APP_EVENT_UPDATE_CONTACTS)
  },
  created() {
    if (this.idlist === 'return') {
      this.$router.go(-2)
    }
  },
  async mounted() {
    // //console.log('contact is Mounted')
    this.loading = true
    if (this.$store.getters['link/getopenStatisticEform']) {
      this.tab = 1
    }
    await this.$store.dispatch('link/saveopenStatisticEform', false)

    // await this.connectContactsList()
    // await this.loadContactListContentById(this.idlist)
    // await this.loadSettingCon(true)
    await this.loadBackground()
    const instances = await this.$store.dispatch('auth/getAllInstances')
    this.listInstances = instances?.value || []
    this.listInstance = instances?.value?.filter(x => x.status == 1)
    // //console.log(this.tableColumns, 'tableColumns')

    this.listInstances.forEach(el => {
      this.dataListChannel.push(`${`${el.instance_id}-${el.phone_number}`}`)
    })

    // await this.loadTableCon()

    try {
      const query = {
        token: this.user.token,
        sub_id: this.user.sub_id,
      }
      const roomsFromPg = await this.$store.dispatch('rooms/getRoomsContactDataPostgres', query)

      // //console.log(roomsFromPg, 'from Contact.vue')

      if (roomsFromPg.status) {
        // this.groupList = roomsFromPg.data.data.filter(x => x.phone_number_show === 'Group' && x.users)
        this.couchAllContact = roomsFromPg.data.data
      }

      // this.couchAllContact = await this.loadContentContactsList()
    } catch (err) {
      console.log(err)
      this.couchAllContact = []
    }

    window.ononline = function () {
      window.location.reload()
    }

    // if (!this.selectFilter) {
    //   if (!this.idlist && this.itemsFilter.length > 0) {
    //     const selectFilter = this.itemsFilter.find(x => x.label === 'All Contacts')
    //     if (selectFilter) {
    //       this.selectFilter = selectFilter.value
    //     }
    //   }
    // }

    this.actions = this.idlist
      ? [
          { label: this.$t('delete'), value: 'delete' },
          { label: this.$t('edit'), value: 'edit' },
          { label: this.$t('setLabel'), value: 'label' },
          { label: this.$t('exportToVcf'), value: 'exportVcf' },
        ]
      : this.user.role === 'Admin'
      ? [
          {
            label:
              this.selectFilter && this.selectFilter.name === 'Excluded' ? this.$t('unarchived') : this.$t('archive'),
            value: 'delete',
          },
          { label: this.$t('edit'), value: 'edit' },
          { label: this.$t('setLabel'), value: 'label' },
          { label: `${this.$t('send')} Broadcast`, value: 'send' },
          { label: `${this.$t('addTo')} Contact List`, value: 'add' },
          { label: this.$t('assignTo'), value: 'assign' },
        ]
      : [
          {
            label:
              this.selectFilter && this.selectFilter.name === 'Excluded' ? this.$t('unarchived') : this.$t('archive'),
            value: 'delete',
          },
          { label: this.$t('edit'), value: 'edit' },
          { label: this.$t('setLabel'), value: 'label' },
          { label: `${this.$t('send')} Broadcast`, value: 'send' },
          { label: `${this.$t('addTo')} Contact List`, value: 'add' },
          { label: this.$t('assignTo'), value: 'assign' },
        ]

    this.$eventBus.$on(process.env.VUE_APP_EVENT_UPDATE_SETTINGS, async payload => {
      this.$store.dispatch('global/setSettingsById', payload)
    })

    // columnsShow
    const surveyQuestion = []
    this.itemsFilter = this.dataContact.map(contact => {
      if (contact.hasOwnProperty('code') && contact.hasOwnProperty('code_name')) {
        return contact.code
      }
    })

    this.dataContact.forEach(el => {
      if (el.answerData) {
        // text, value, align

        el.answerData.forEach(data => {
          const obj = {
            nameAtContactList: el.name,
            phone_number: el.phone_number,
          }
          for (const key in data) {
            if (!key.includes('question')) {
              const headerObj = {
                align: 'center',
              }
              headerObj.text = key
              headerObj.value = key
              obj[key] = data[key]

              surveyQuestion.push(key)
              this.dataChart[key] = JSON.parse(JSON.stringify(this.donutChart))
              if (!this.headerAllContactAnswerInSummary.find(el => el.value === headerObj.value)) {
                this.headerAllContactAnswerInSummary.push(headerObj)
              }
            }
          }
          this.allContactAnswerInSummary.push(obj)
        })
      }
    })
    //console.log('🚀 ~ file: Contact.vue ~ line 2265 ~ mounted ~ this.dataContact', this.dataContact)

    // this.surveyQuestion

    this.blockColumns = _uniq(surveyQuestion)
    this.columnsShow.forEach(el => {
      if (
        el.value !== 'last_order_time' &&
        el.value !== 'total_order' &&
        el.value !== 'order_count' &&
        el.value !== 'answerData' &&
        el.value !== 'has_wa' &&
        el.value !== 'id' &&
        el.value !== 'name' &&
        el.value !== 'phone_number' &&
        el.value !== 'created_at' &&
        el.value !== 'updated_at' &&
        el.name !== ''
      ) {
        this.dataChart[el.name] = JSON.parse(JSON.stringify(this.donutChart))
        this.blockColumns.push(el.name)
      }
    })

    for (let i = 0; i < this.blockColumns.length; i++) {
      let run = false
      const answer = []
      const counting = []
      const color = []
      this.dataContact.forEach(el => {
        if (el[this.blockColumns[i]]) {
          run = true
          const findCheckker = answer.findIndex(item => {
            return item === el[this.blockColumns[i]]
          })
          if (findCheckker == -1) {
            counting.push(1)
            color.push(`#${Math.floor(Math.random() * 16777215).toString(16)}`)
            answer.push(el[this.blockColumns[i]])
          } else {
            counting[findCheckker] += 1
          }
        }

        if (el.hasOwnProperty('answerData') && !run) {
          el.answerData.forEach(el2 => {
            if (el2.hasOwnProperty(this.blockColumns[i])) {
              if (Array.isArray(el2[`${this.blockColumns[i]}`])) {
                const findCheckker = answer.findIndex(el => {
                  return el === el2[`${this.blockColumns[i]}`].join(',')
                })
                if (findCheckker == -1) {
                  counting.push(1)
                  color.push(`#${Math.floor(Math.random() * 16777215).toString(16)}`)
                  answer.push(el2[`${this.blockColumns[i]}`].join(','))
                } else {
                  counting[findCheckker] += 1
                }
              } else {
                const findCheckker = answer.findIndex(el => {
                  return el === el2[`${this.blockColumns[i]}`]
                })
                if (findCheckker == -1) {
                  counting.push(1)
                  color.push(`#${Math.floor(Math.random() * 16777215).toString(16)}`)
                  answer.push(el2[`${this.blockColumns[i]}`])
                } else {
                  counting[findCheckker] += 1
                }
              }
            } else {
              this.dataChart[this.blockColumns[i]].series[1] += 1
            }
          })
        }
      })

      this.dataChart[this.blockColumns[i]].series = counting
      this.dataChart[this.blockColumns[i]].chartOptions.labels = answer
      this.dataChart[this.blockColumns[i]].chartOptions.colors = color
    }

    await this.getStatisticChart(this.dates)

    // if (this.blockColumns.length > 0) {
    //   const count = 0
    //   for (let i = 0; i < this.blockColumns.length; i++) {
    //     this.dataChart[this.blockColumns[i]] = JSON.parse(JSON.stringify(this.donutChart))
    //   }
    //   for (let i = 0; i < this.blockColumns.length; i++) {
    //     this.dataContact.forEach(el => {
    //       if (el.hasOwnProperty(this.blockColumns[i])) {
    //         this.dataChart[this.blockColumns[i]].series[0] += 1
    //       } else {
    //         this.dataChart[this.blockColumns[i]].series[1] += 1
    //       }
    //     })
    //   }
    // }

    // //console.log(this.tableColumns, ' == COLUMNS')
    // //console.log(this.dates)
    this.setIsConfig()

    setTimeout((this.loading = false), 5000)
  },

  methods: {
    async filterCode() {
      if (!this.selectFilter) {
        const surveyQuestion = []
        this.dataChart = {}
        this.blockColumns = []
        this.donutChart = {
          series: [1, 1],
          chartOptions: {
            legend: {
              show: true,
              position: 'bottom',
              fontSize: '14px',
              fontFamily: '"Inter", sans-serif',
            },
            colors: ['#ffe700', '#00d4bd'],
            dataLabels: {
              enabled: true,
              formatter(val) {
                // eslint-disable-next-line radix
                return `${parseInt(val)}%`
              },
            },

            // plotOptions: {
            //   pie: {
            //     donut: {
            //       labels: {
            //         show: true,
            //         name: {
            //           fontSize: '2rem',
            //           fontFamily: '"Inter", sans-serif',
            //         },
            //         value: {
            //           fontSize: '1rem',
            //           fontFamily: '"Inter", sans-serif',
            //           formatter(val) {
            //             // eslint-disable-next-line radix
            //             return `${parseInt(val)}%`
            //           },
            //         },
            //         total: {
            //           show: true,
            //           fontSize: '1.5rem',
            //           label: 'Operational',
            //           formatter() {
            //             return '31%'
            //           },
            //         },
            //       },
            //     },
            //   },
            // },
            labels: ['Response', 'No Response'],
            responsive: [
              {
                breakpoint: 992,
                options: {
                  chart: {
                    height: 380,
                  },
                  legend: {
                    position: 'bottom',
                  },
                },
              },
              {
                breakpoint: 576,
                options: {
                  chart: {
                    height: 320,
                  },
                  plotOptions: {
                    pie: {
                      donut: {
                        labels: {
                          show: true,
                          name: {
                            fontSize: '1.5rem',
                          },
                          value: {
                            fontSize: '1rem',
                          },
                          total: {
                            fontSize: '1.5rem',
                          },
                        },
                      },
                    },
                  },
                  legend: {
                    show: true,
                  },
                },
              },
            ],
          },
        }
        this.headerAllContactAnswerInSummary = [
          { text: 'Contact Name', value: 'nameAtContactList', align: 'center' },
          { text: 'Phone Number', value: 'phone_number', align: 'center' },
        ]
        this.contactListTable = JSON.parse(JSON.stringify(this.contactListTableOrigin))
        this.dataContact.forEach(el => {
          if (el.answerData) {
            // text, value, align

            el.answerData.forEach(data => {
              const obj = {
                nameAtContactList: el.name,
                phone_number: el.phone_number,
              }
              for (const key in data) {
                if (!key.includes('question')) {
                  const headerObj = {
                    align: 'center',
                  }
                  headerObj.text = key
                  headerObj.value = key
                  obj[key] = data[key]

                  surveyQuestion.push(key)
                  this.dataChart[key] = JSON.parse(JSON.stringify(this.donutChart))
                  if (!this.headerAllContactAnswerInSummary.find(el => el.value === headerObj.value)) {
                    this.headerAllContactAnswerInSummary.push(headerObj)
                  }
                }
              }
              this.allContactAnswerInSummary.push(obj)
            })
          }
        })

        // this.surveyQuestion

        this.blockColumns = _uniq(surveyQuestion)
        this.columnsShow.forEach(el => {
          if (
            el.value !== 'last_order_time' &&
            el.value !== 'total_order' &&
            el.value !== 'order_count' &&
            el.value !== 'answerData' &&
            el.value !== 'has_wa' &&
            el.value !== 'id' &&
            el.value !== 'name' &&
            el.value !== 'phone_number' &&
            el.value !== 'created_at' &&
            el.value !== 'updated_at' &&
            el.name !== ''
          ) {
            this.dataChart[el.name] = JSON.parse(JSON.stringify(this.donutChart))
            this.blockColumns.push(el.name)
          }
        })

        for (let i = 0; i < this.blockColumns.length; i++) {
          let run = false
          const answer = []
          const counting = []
          const color = []
          this.dataContact.forEach(el => {
            if (el[this.blockColumns[i]]) {
              run = true
              const findCheckker = answer.findIndex(item => {
                return item === el[this.blockColumns[i]]
              })
              if (findCheckker == -1) {
                counting.push(1)
                color.push(`#${Math.floor(Math.random() * 16777215).toString(16)}`)
                answer.push(el[this.blockColumns[i]])
              } else {
                counting[findCheckker] += 1
              }
            }

            if (el.hasOwnProperty('answerData') && !run) {
              el.answerData.forEach(el2 => {
                if (el2.hasOwnProperty(this.blockColumns[i])) {
                  if (Array.isArray(el2[`${this.blockColumns[i]}`])) {
                    const findCheckker = answer.findIndex(el => {
                      return el === el2[`${this.blockColumns[i]}`].join(',')
                    })
                    if (findCheckker == -1) {
                      counting.push(1)
                      color.push(`#${Math.floor(Math.random() * 16777215).toString(16)}`)
                      answer.push(el2[`${this.blockColumns[i]}`].join(','))
                    } else {
                      counting[findCheckker] += 1
                    }
                  } else {
                    const findCheckker = answer.findIndex(el => {
                      return el === el2[`${this.blockColumns[i]}`]
                    })
                    if (findCheckker == -1) {
                      counting.push(1)
                      color.push(`#${Math.floor(Math.random() * 16777215).toString(16)}`)
                      answer.push(el2[`${this.blockColumns[i]}`])
                    } else {
                      counting[findCheckker] += 1
                    }
                  }
                } else {
                  this.dataChart[this.blockColumns[i]].series[1] += 1
                }
              })
            }
          })

          this.dataChart[this.blockColumns[i]].series = counting
          this.dataChart[this.blockColumns[i]].chartOptions.labels = answer
          this.dataChart[this.blockColumns[i]].chartOptions.colors = color
        }

        await this.getStatisticChart(this.dates)
      } else {
        const surveyQuestion = []
        this.dataChart = {}
        this.blockColumns = []
        this.donutChart = {
          series: [1, 1],
          chartOptions: {
            legend: {
              show: true,
              position: 'bottom',
              fontSize: '14px',
              fontFamily: '"Inter", sans-serif',
            },
            colors: ['#ffe700', '#00d4bd'],
            dataLabels: {
              enabled: true,
              formatter(val) {
                // eslint-disable-next-line radix
                return `${parseInt(val)}%`
              },
            },

            // plotOptions: {
            //   pie: {
            //     donut: {
            //       labels: {
            //         show: true,
            //         name: {
            //           fontSize: '2rem',
            //           fontFamily: '"Inter", sans-serif',
            //         },
            //         value: {
            //           fontSize: '1rem',
            //           fontFamily: '"Inter", sans-serif',
            //           formatter(val) {
            //             // eslint-disable-next-line radix
            //             return `${parseInt(val)}%`
            //           },
            //         },
            //         total: {
            //           show: true,
            //           fontSize: '1.5rem',
            //           label: 'Operational',
            //           formatter() {
            //             return '31%'
            //           },
            //         },
            //       },
            //     },
            //   },
            // },
            labels: ['Response', 'No Response'],
            responsive: [
              {
                breakpoint: 992,
                options: {
                  chart: {
                    height: 380,
                  },
                  legend: {
                    position: 'bottom',
                  },
                },
              },
              {
                breakpoint: 576,
                options: {
                  chart: {
                    height: 320,
                  },
                  plotOptions: {
                    pie: {
                      donut: {
                        labels: {
                          show: true,
                          name: {
                            fontSize: '1.5rem',
                          },
                          value: {
                            fontSize: '1rem',
                          },
                          total: {
                            fontSize: '1.5rem',
                          },
                        },
                      },
                    },
                  },
                  legend: {
                    show: true,
                  },
                },
              },
            ],
          },
        }
        this.headerAllContactAnswerInSummary = [
          { text: 'Contact Name', value: 'nameAtContactList', align: 'center' },
          { text: 'Phone Number', value: 'phone_number', align: 'center' },
        ]
        this.contactListTable = JSON.parse(
          JSON.stringify(this.contactListTableOrigin.filter(contact => contact.code === this.selectFilter)),
        )
        this.dataContact.forEach(el => {
          if (el.answerData) {
            // text, value, align

            el.answerData.forEach(data => {
              const obj = {
                nameAtContactList: el.name,
                phone_number: el.phone_number,
              }
              for (const key in data) {
                if (!key.includes('question')) {
                  const headerObj = {
                    align: 'center',
                  }
                  headerObj.text = key
                  headerObj.value = key
                  obj[key] = data[key]

                  surveyQuestion.push(key)
                  this.dataChart[key] = JSON.parse(JSON.stringify(this.donutChart))
                  if (!this.headerAllContactAnswerInSummary.find(el => el.value === headerObj.value)) {
                    this.headerAllContactAnswerInSummary.push(headerObj)
                  }
                }
              }
              this.allContactAnswerInSummary.push(obj)
            })
          }
        })

        // this.surveyQuestion

        this.blockColumns = _uniq(surveyQuestion)
        this.columnsShow.forEach(el => {
          if (
            el.value !== 'last_order_time' &&
            el.value !== 'total_order' &&
            el.value !== 'order_count' &&
            el.value !== 'answerData' &&
            el.value !== 'has_wa' &&
            el.value !== 'id' &&
            el.value !== 'name' &&
            el.value !== 'phone_number' &&
            el.value !== 'created_at' &&
            el.value !== 'updated_at' &&
            el.name !== ''
          ) {
            this.dataChart[el.name] = JSON.parse(JSON.stringify(this.donutChart))
            this.blockColumns.push(el.name)
          }
        })

        for (let i = 0; i < this.blockColumns.length; i++) {
          let run = false
          const answer = []
          const counting = []
          const color = []
          this.dataContact.forEach(el => {
            if (el[this.blockColumns[i]]) {
              run = true
              const findCheckker = answer.findIndex(item => {
                return item === el[this.blockColumns[i]]
              })
              if (findCheckker == -1) {
                counting.push(1)
                color.push(`#${Math.floor(Math.random() * 16777215).toString(16)}`)
                answer.push(el[this.blockColumns[i]])
              } else {
                counting[findCheckker] += 1
              }
            }

            if (el.hasOwnProperty('answerData') && !run) {
              el.answerData.forEach(el2 => {
                if (el2.hasOwnProperty(this.blockColumns[i])) {
                  if (Array.isArray(el2[`${this.blockColumns[i]}`])) {
                    const findCheckker = answer.findIndex(el => {
                      return el === el2[`${this.blockColumns[i]}`].join(',')
                    })
                    if (findCheckker == -1) {
                      counting.push(1)
                      color.push(`#${Math.floor(Math.random() * 16777215).toString(16)}`)
                      answer.push(el2[`${this.blockColumns[i]}`].join(','))
                    } else {
                      counting[findCheckker] += 1
                    }
                  } else {
                    const findCheckker = answer.findIndex(el => {
                      return el === el2[`${this.blockColumns[i]}`]
                    })
                    if (findCheckker == -1) {
                      counting.push(1)
                      color.push(`#${Math.floor(Math.random() * 16777215).toString(16)}`)
                      answer.push(el2[`${this.blockColumns[i]}`])
                    } else {
                      counting[findCheckker] += 1
                    }
                  }
                } else {
                  this.dataChart[this.blockColumns[i]].series[1] += 1
                }
              })
            }
          })

          this.dataChart[this.blockColumns[i]].series = counting
          this.dataChart[this.blockColumns[i]].chartOptions.labels = answer
          this.dataChart[this.blockColumns[i]].chartOptions.colors = color
        }

        await this.getStatisticChart(this.dates)
      }
    },
    async getOpsStatistic(date) {
      // this.$refs.menu.save(date)
      if (date && date.length === 2) {
        if (new Date(date[0]).getTime() > new Date(date[1]).getTime()) {
          this.rangeDates = this.getDateRange(new Date(date[1]), new Date(date[0]))
        } else {
          this.rangeDates = this.getDateRange(new Date(date[0]), new Date(date[1]))
        }
      } else {
        const startDate = `${new Date((new Date(date[0]).getTime() / 1000 - 72000) * 1000).getFullYear()}-${
          new Date((new Date(date[0]).getTime() / 1000 - 72000) * 1000).getMonth() + 1
        }-${new Date((new Date(date[0]).getTime() / 1000 - 72000) * 1000).getDate()}`
        const endDate = `${new Date((new Date(date[0]).getTime() / 1000 + 72000) * 1000).getFullYear()}-${
          new Date((new Date(date[0]).getTime() / 1000 + 72000) * 1000).getMonth() + 1
        }-${new Date((new Date(date[0]).getTime() / 1000 + 72000) * 1000).getDate()}`

        this.rangeDates = this.getDateRange(new Date(startDate), new Date(endDate))
      }
      const chart = {
        chart: {
          stacked: true,
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: [],
            },
          },
        },
        colors: ['#008FFB', '#00E396', '#CED4DC', '#ff4c51', '#ffb400', '#1F979E'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        fill: {
          type: 'gradient',
          gradient: {
            opacityFrom: 0.6,
            opacityTo: 0.8,
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
        },

        xaxis: {
          categories: [],
        },
      }
      const range = JSON.parse(JSON.stringify(this.rangeDates))
      this.chartCreatedAt2 = chart
      this.chartCreatedAt2.xaxis.categories = range
      const statMessage = await this.$store.dispatch('rooms/statisticMessage', {
        subId: this.user.sub_id,
        dateRange: this.rangeDates,
        listChannel: this.dataListChannel,
        contactList: this.idlist,
      })

      // //console.log(statMessage)
      if (statMessage.data && statMessage.data.status) {
        const newMsg = []
        const newMsgUniq = []
        const incomingMsg = []
        const outcomingMsg = []
        const incomingMsgUniq = []
        const outcomingMsgUniq = []
        statMessage.data.allStatisticChatIncoming.forEach(el => {
          const name = this.dataListChannel.find(ind => ind.split('-')[0] === el.name)
          const objincoming = {
            name: this.listInstances.find(instance => instance.phone_number === name.split('-')[1]).label,
            data: el.data,
          }
          incomingMsg.push(objincoming)
        })
        statMessage.data.allStatisticChatOutcoming.forEach(el => {
          const name = this.dataListChannel.find(ind => ind.split('-')[0] === el.name)
          const objoutcoming = {
            name: this.listInstances.find(instance => instance.phone_number === name.split('-')[1]).label,
            data: el.data,
          }

          // //console.log(objoutcoming)
          outcomingMsg.push(objoutcoming)
        })
        statMessage.data.allStatisticNewChat.forEach(el => {
          const name = this.dataListChannel.find(ind => ind.split('-')[0] === el.name)
          const objincoming = {
            name: this.listInstances.find(instance => instance.phone_number === name.split('-')[1]).label,
            data: el.data,
          }
          newMsg.push(objincoming)
        })

        /// uniq
        statMessage.data.allStatisticChatIncomingUniq.forEach(el => {
          const name = this.dataListChannel.find(ind => ind.split('-')[0] === el.name)
          const objincoming = {
            name: this.listInstances.find(instance => instance.phone_number === name.split('-')[1]).label,
            data: el.data,
          }
          incomingMsgUniq.push(objincoming)
        })
        statMessage.data.allStatisticChatOutcomingUniq.forEach(el => {
          const name = this.dataListChannel.find(ind => ind.split('-')[0] === el.name)
          const objoutcoming = {
            name: this.listInstances.find(instance => instance.phone_number === name.split('-')[1]).label,
            data: el.data,
          }

          // //console.log(objoutcoming)
          outcomingMsgUniq.push(objoutcoming)
        })
        statMessage.data.allStatisticNewChatUniq.forEach(el => {
          const name = this.dataListChannel.find(ind => ind.split('-')[0] === el.name)
          const objincoming = {
            name: this.listInstances.find(instance => instance.phone_number === name.split('-')[1]).label,
            data: el.data,
          }
          newMsgUniq.push(objincoming)
        })

        this.messageStatisticIncomingUniq = JSON.parse(JSON.stringify(incomingMsgUniq))

        this.messageStatisticOutcomingUniq = JSON.parse(JSON.stringify(outcomingMsgUniq))

        this.messageStatisticUniq = JSON.parse(JSON.stringify(newMsgUniq))

        this.messageStatisticIncoming = JSON.parse(JSON.stringify(incomingMsg))

        this.messageStatisticOutcoming = JSON.parse(JSON.stringify(outcomingMsg))

        this.messageStatisticNew = JSON.parse(JSON.stringify(newMsg))
      }

      this.menu = false
    },
    closeShowSnackbarErr() {
      this.showSnackbarErr = false
    },
    wrapCsvValue(val, formatFn) {
      let formatted = formatFn !== void 0 ? formatFn(val) : val

      formatted = formatted === void 0 || formatted === null ? '' : String(formatted)

      formatted = formatted?.split('"').join('""')

      /**
       * Excel accepts \n and \r in strings, but some other CSV parsers do not
       * Uncomment the next two lines to escape new lines
       */
      // .split('\n').join('\\n')
      // .split('\r').join('\\r')

      return `"${formatted}"`
    },
    exportTableList() {
      // naive encoding to csv format
      //console.log(this.headerAllContactAnswerInSummary, 'headerAllContactAnswerInSummary')
      //console.log(this.allContactAnswerInSummary, 'allContactAnswerInSummary')
      const content = [this.headerAllContactAnswerInSummary?.map(col => this.wrapCsvValue(col.text))]
        .concat(
          this.allContactAnswerInSummary?.map(row =>
            this.headerAllContactAnswerInSummary
              .map(col => {
                if (col.field === 'channel') {
                  return row.channel
                }
                if (col.field === 'statistic') {
                  return `"sent: ${row.statistic.sent === undefined ? '0' : row.statistic.sent} received: ${
                    row.statistic.received === undefined ? '0' : row.statistic.received
                  } read: ${row.statistic.read === undefined ? '0' : row.statistic.read} replied: ${
                    row.statistic.replied === undefined ? '0' : row.statistic.replied
                  }"`
                }

                return this.wrapCsvValue(row[col.value], undefined)
              })
              .join(','),
          ),
        )
        .join('\r\n')

      const anchor = document.createElement('a')
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(content)}`
      anchor.target = '_blank'
      anchor.download = `${new Date()}-${this.itemsBreadcrumbs[1].text}.csv`
      anchor.click()
    },
    closeDialogConfig() {
      this.surveyConfig = {
        id: '',
        status: false,
      }
      this.dialogConfig = false
    },
    async seeAnswerData(answers) {
      // //console.log(answers)
      this.seeAnswerDialog = true
      this.questions = []
      if (answers.length) {
        const { data } = await this.getEFormByContactList({ contactList: this.$route.params.idlist })
        let questions = []

        answers.forEach(answer => {
          for (const question in answer) {
            // //console.log(data.selectedFields, question)
            if (data.selectedFields.length !== 0 && data.selectedFields.includes(question)) {
              questions.push(question)
            } else if (data.selectedFields.length !== 0 && !data.selectedFields.includes(question)) {
              questions = data.selectedFields
            } else if (data.selectedFields.length === 0) {
              questions.push(question)
            }
          }
        })

        _uniq(questions).forEach(question => {
          if (!question.includes('question') && !question.includes('list_name')) {
            this.questions.push({
              text: question,
              value: question,
              align: 'center',
            })
          }
        })

        const currentAnswers = answers
        currentAnswers.forEach(answer => {
          data.selectedFields.forEach(field => {
            if (!answer[field]) {
              answer[field] = 'Did not answer'
            }
          })
        })
      }
      this.answers = answers
    },
    async seeDataVerification(item) {
      if (item.photoIdCard.hasOwnProperty('photoId') && item.photoIdCard.hasOwnProperty('selfPhotoId')) {
        this.photoIdCard = item.photoIdCard.photoId
        this.selfPhotoIdCard = item.photoIdCard.selfPhotoId
        this.hasUploadPhotoIdCard = true
        if (item.hasOwnProperty('reasonRejected')) {
          this.hasReasonRejected = true
          this.reasonRejectedPerUser = item.reasonRejected
        } else {
          this.hasReasonRejected = false
        }
      } else {
        this.hasUploadPhotoIdCard = false
      }
      this.seeDataVerificationDialog = true
    },
    async exportVcf() {
      const vCard = vCardsJS()
      try {
        if (this.selectedTableData.length === 0) {
          this.dataContact.forEach(el => {
            const firstName = el.name.split(' ')[0]
            const middleName = el.name.split(' ')[1] ? el.name.split(' ')[1] : ''
            const lastName = el.name.split(' ')[2] ? el.name.split(' ')[2] : ''
            vCard.firstName = firstName
            vCard.middleName = middleName
            vCard.lastName = lastName
            vCard.cellPhone = el.phone_number
            vCard.uid = el._uid
            vCard.version = '3.0'

            const anchor = document.createElement('a')
            anchor.href = `data:text/vcard;charset=utf-8,${vCard.getFormattedString()}`
            anchor.target = '_blank'
            anchor.download = `${el.name}.vcf`
            anchor.click()
          })
        } else {
          this.selectedTableData.forEach(el => {
            const firstName = el.name.split(' ')[0]
            const middleName = el.name.split(' ')[1] ? el.name.split(' ')[1] : ''
            const lastName = el.name.split(' ')[2] ? el.name.split(' ')[2] : ''
            vCard.firstName = firstName
            vCard.middleName = middleName
            vCard.lastName = lastName
            vCard.cellPhone = el.phone_number
            vCard.uid = el._uid
            vCard.version = '3.0'

            const anchor = document.createElement('a')
            anchor.href = `data:text/vcard;charset=utf-8,${vCard.getFormattedString()}`
            anchor.target = '_blank'
            anchor.download = `${el.name}.vcf`
            anchor.click()
          })
        }

        return true
      } catch (err) {
        return false
      }

      // // link to image
      // vCard.photo.attachFromUrl('https://avatars2.githubusercontent.com/u/5659221?v=3&s=460', 'JPEG')

      // // or embed image
      // vCard.photo.attachFromUrl('/path/to/file.jpeg')

      // vCard.workPhone = '312-555-1212'
      // vCard.birthday = new Date(1985, 0, 1)
      // vCard.title = 'Software Developer'
      // vCard.url = 'https://github.com/enesser'
      // vCard.workUrl = 'https://acme-corporation/enesser'
      // vCard.note = 'Notes on Eric'

      // // set other vitals
      // vCard.nickname = 'Scarface'
      // vCard.namePrefix = 'Mr.'
      // vCard.nameSuffix = 'JR'
      // vCard.gender = 'M'
      // vCard.anniversary = new Date(2004, 0, 1)
      // vCard.role = 'Software Development'

      // // set other phone numbers
      // vCard.homePhone = '312-555-1313'
      // vCard.cellPhone = '312-555-1414'
      // vCard.pagerPhone = '312-555-1515'

      // // set fax/facsimile numbers
      // vCard.homeFax = '312-555-1616'
      // vCard.workFax = '312-555-1717'

      // // set email addresses
      // vCard.email = 'e.nesser@emailhost.tld'
      // vCard.workEmail = 'e.nesser@acme-corporation.tld'

      // // set logo of organization or personal logo (also supports embedding, see above)
      // vCard.logo.attachFromUrl('https://avatars2.githubusercontent.com/u/5659221?v=3&s=460', 'JPEG')

      // // set URL where the vCard can be found
      // vCard.source = 'http://mywebpage/myvcard.vcf'

      // // set address information
      // vCard.homeAddress.label = 'Home Address'
      // vCard.homeAddress.street = '123 Main Street'
      // vCard.homeAddress.city = 'Chicago'
      // vCard.homeAddress.stateProvince = 'IL'
      // vCard.homeAddress.postalCode = '12345'
      // vCard.homeAddress.countryRegion = 'United States of America'

      // vCard.workAddress.label = 'Work Address'
      // vCard.workAddress.street = '123 Corporate Loop\nSuite 500'
      // vCard.workAddress.city = 'Los Angeles'
      // vCard.workAddress.stateProvince = 'CA'
      // vCard.workAddress.postalCode = '54321'
      // vCard.workAddress.countryRegion = 'United States of America'

      // // set social media URLs
      // vCard.socialUrls.facebook = 'https://...'
      // vCard.socialUrls.linkedIn = 'https://...'
      // vCard.socialUrls.twitter = 'https://...'
      // vCard.socialUrls.flickr = 'https://...'
      // vCard.socialUrls.custom = 'https://...'

      // you can also embed photos from files instead of attaching via URL
      // vCard.photo.embedFromFile('photo.jpg')
      // vCard.logo.embedFromFile('logo.jpg')
    },
    async getStatisticChart(date) {
      if (date && date.length === 2) {
        if (new Date(date[0]).getTime() > new Date(date[1]).getTime()) {
          this.rangeDates = this.getDateRange(new Date(date[1]), new Date(date[0]))
        } else {
          this.rangeDates = this.getDateRange(new Date(date[0]), new Date(date[1]))
        }
      } else {
        const startDate = `${new Date((new Date(date[0]).getTime() / 1000 - 72000) * 1000).getFullYear()}-${
          new Date((new Date(date[0]).getTime() / 1000 - 72000) * 1000).getMonth() + 1
        }-${new Date((new Date(date[0]).getTime() / 1000 - 72000) * 1000).getDate()}`
        const endDate = `${new Date((new Date(date[0]).getTime() / 1000 + 72000) * 1000).getFullYear()}-${
          new Date((new Date(date[0]).getTime() / 1000 + 72000) * 1000).getMonth() + 1
        }-${new Date((new Date(date[0]).getTime() / 1000 + 72000) * 1000).getDate()}`

        this.rangeDates = this.getDateRange(new Date(startDate), new Date(endDate))
      }
      const chart = {
        chart: {
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: [],
            },
          },
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'left',
          fontSize: '14px',
          fontFamily: '"Inter", sans-serif',
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: false,
          curve: 'straight',
        },
        colors: [
          // $themeColors.warning,
          // $themeColors.error,
          // $themeColors.secondary,
          // $themeColors.info,
          // $themeColors.success,

          '#ab7efd',
          '#F0D9FF',
          '#e0cffe',
          '#BFA2DB',
          '#b992fe',
          '#b391ec',
          '#b9a3dc',
          '#bdb5ca',
          '#bec6b9',
          '#8c62dd',
          '#966be8',
          '#a175f2',
          '#b588ff',
          '#c091ff',
          '#ca9bff',
          '#b089f3',
          '#b594e8',
          '#b89fde',
          '#bba9d4',
          '#bdb4c9',
          '#bebebe',
          '#bb93fe',
          '#caa8ff',
          '#d9beff',
          '#e6d3ff',
          '#f3e9ff',
          '#ffffff',
          '#8d68ce',
          '#6f53a1',
          '#533f76',
          '#392c4e',
        ],
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        fill: {
          opacity: 1,
          type: ['solid', 'gradient'],
        },
        xaxis: {
          categories: [],
        },
      }
      this.userDataCreated[0] = {
        data: [],
        name: 'Data Inserted',
      }

      // //console.log(this.rangeDates, '<<<<<<<<<aaaaaaaaaaaaaaaaaaaaaaaaa')
      for (let i = 0; i < this.rangeDates.length; i++) {
        this.userDataCreated[0].data.push(0)
        this.dataContact.forEach(el => {
          try {
            if (this.rangeDates[i] === new Date(el.created_at).toISOString().substring(0, 10)) {
              this.userDataCreated[0].data[i] += 1
            }
          } catch (e) {
            //console.log(e)
          }
        })
      }
      const range = JSON.parse(JSON.stringify(this.rangeDates))
      this.chartCreatedAt = chart

      this.chartCreatedAt.xaxis.categories = range
      await this.getOpsStatistic(this.dates)
      this.menu = false

      // //console.log(this.userDataCreated)
    },
    getDateRange(startDate, endDate) {
      const dates = []
      let currentDate = startDate
      const addDays = function (days) {
        const date = new Date(this.valueOf())
        date.setDate(date.getDate() + days)

        return date
      }
      while (currentDate <= endDate) {
        dates.push(new Date(currentDate).toISOString().substring(0, 10))
        currentDate = addDays.call(currentDate, 1)
      }

      // //console.log(dates, 'oooooooooooo')
      return dates
    },
    async loadTableCon() {
      // const obj = (await this.loadContactsAll()).contacts.filter(el => el._id !== el.phone_number)
      // const listContactObj = this.listContactValue.map(el => {
      //   // //console.log(el.id)
      //   el.active_contact = obj.filter(elm => elm._id.substring(0, 8) == el.id).length
      //   return el
      // })
      // this.listContactValue = [...listContactObj]
      // this.totalContact = obj.totalContact
    },
    async loadSettingCon(isExist = false) {
      // if (!isExist) {
      //   await this.loadSettings()
      // }
      // const res = await this.loadSettingContactsList(this.allSettings)
      // this.labelData = res.labelData
      // // this.contactListSetting = res.contactListSetting
      // this.listContactValue = res.listContactValue
      // this.deleteUnusedContact(this.contactListTable, this.listContactValue)
    },
    urlify(message) {
      if (!message) return ''

      const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g

      message = message.replace(urlRegex, function (url) {
        let hyperlink = url

        if (!hyperlink.match('^https?://')) {
          hyperlink = `http://${hyperlink}`
        }

        if (url.includes('wa.send-cheap.com')) {
          return `<a href="${hyperlink}" target="_blank">Attachment</a>`
        }

        return `<a href="${hyperlink}" target="_blank">Attachment</a>`
      })

      return message

      // or alternatively
      // return text.replace(urlRegex, '<a href="$1">$1</a>')
    },
    selectionSort(data) {
      const filterArray = (arr, idx1, idx2) => {
        ;[arr[idx1], arr[idx2]] = [arr[idx2], arr[idx1]]
      }

      for (let i = 0; i < data.length; i += 1) {
        let lowers = i

        for (let j = i + 1; j < data.length; j += 1) {
          if (data[lowers].order_number > data[j].order_number) {
            lowers = j
          }
        }
        if (i !== lowers) filterArray(data, i, lowers)
      }

      return data
    },

    async loadBackground() {
      // await this.connectSettings()
      await this.loadSetting()

      // await this.connectContacts(false)

      await this.loadTable()
    },
    async editFavourite(item) {
      await this.changeFavouriteContact(item, async success => {
        if (success) {
          this.replicateFromPouchDbGlobal(process.env.VUE_APP_DB_CONTACTS_NAME, this.user, async info => {
            await this.loadTable()
          })
        }
      })
    },

    // contact list
    maxTextLength(text) {
      if (text.length < 10) {
        return text
      }
      let tmp = ''
      for (let i = 0; i < 9; i++) {
        tmp += text[i]
      }
      tmp += '...'

      return tmp
    },
    deleteColumn(item, index) {
      this.listDraggable.splice(index, 1)
    },
    openNotes(note) {
      this.notes = note
      this.dialogNotes = true
    },

    closeDialog(fromDialog = true) {
      this.addBroadcastDialog = false

      this.selectedAction = ''

      if (fromDialog) {
        this.$router.push({
          name: 'broadcast-list',
        })
      }
    },

    importContact() {
      //console.log(this.labelData, this.idlist, this.totalContact, 'ini semua nonsens')
      this.$refs.dialogImport.open(this.labelData, [], this.idlist, this.totalContact).then(async confirm => {
        if (confirm.status) {
          this.loading = true

          let newContacts = confirm.data.map(newContact => {
            newContact.created_by = this.user.email
            newContact.updated_by = this.user.email

            return newContact
          })

          newContacts = this.contactListTable.concat(newContacts)

          const phoneNumbers = []
          newContacts.forEach(contact => {
            phoneNumbers.push(contact.phone_number)
          })

          const instanceId = `${this.listInstance[0].sub_id}-${this.listInstance[0].instance_id}`
          const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}exists/${instanceId}`
          this.$axiosLimit
            .post(endpoint, { phone_numbers: phoneNumbers })
            .then(async ({ data }) => {
              newContacts.forEach(contact => {
                if (data.data[contact.phone_number]) {
                  contact.type = 'Whatsapp'
                } else {
                  contact.type = 'Phone number'
                }
              })
            })
            .catch(error => {
              console.log(error, 'data')
            })

          this.currentList.contacts = _uniqBy(newContacts, 'phone_number')

          await this.$store.dispatch('global/insertOrUpdateContactList', {
            sub_id: this.user.sub_id,
            token: this.user.token,
            ...this.currentList,
          })

          await this.loadTable()
          this.loading = false

          // const phoneNumbers = []
          // const checkData = []
          // data.forEach(x => {
          //   if (!x.has_wa) {
          //     checkData.push(x)
          //     phoneNumbers.push(x.phone_number)
          //   }
          // })
          // if (this.listInstance.length > 0) {
          //   const instanceId = `${this.listInstance[0].sub_id}-${this.listInstance[0].instance_id}`
          //   const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}exists/${instanceId}`
          //   let isUpdate = false
          //   this.$axiosLimit
          //     .post(endpoint, { phone_numbers: phoneNumbers })
          //     .then(async response => {
          //       checkData.forEach(async (x, i) => {
          //         if (response.data.data[x.phone_number]) {
          //           const foundData = data.find(y => y.phone_number === x.phone_number)
          //           if (foundData) {
          //             isUpdate = true
          //             foundData.has_wa = 'Yes'
          //             foundData.type = 'Whatsapp'
          //             foundData.created_at = Date.now()
          //           }
          //         } else {
          //           data[i].type = 'Phone number'
          //         }
          //         data[i].created_by = this.user.email
          //         data[i].updated_by = this.user.email
          //       })
          //       if (isUpdate) {
          //         this.currentList.contacts = _uniqBy(data, 'phone_number')
          //         // //console.log(this.currentList, 'data')
          //         //console.log('masuk sini', 'data')

          //         await this.$store.dispatch('global/insertOrUpdateContactList', {
          //           sub_id: this.user.sub_id,
          //           token: this.user.token,
          //           ...this.currentList,
          //         })

          //         await this.loadTable()
          //       }
          //     })
          //     .catch(error => {
          //       console.log(error)
          //     })
          // }
        }
      })
    },

    exportTable() {
      // naive encoding to csv format
      const self = this
      const content = [this.columnsExport.map(col => wrapCsvValue(col.text))]
        .concat(
          this.dataContact.map(row =>
            this.columnsExport
              .map(col => {
                if (col.field === 'last_interaction') {
                  if (row[col.field]) {
                    return row[col.field].phone_number
                      ? row[col.field].phone_number.substr(0, row[col.field].phone_number.indexOf('@'))
                      : ''
                  }

                  return ''
                }
                if (col.field === 'last_message' || col.field === 'last_reply') {
                  if (row[col.field]) {
                    return self.$moment(row[col.field] * 1000).format('DD-MM-YYYY HH:mm:ss')
                  }

                  return ''
                }

                return wrapCsvValue(
                  typeof col.field === 'function' ? col.field(row) : row[col.field === void 0 ? col.text : col.field],
                  col.format,
                )
              })
              .join(','),
          ),
        )
        .join('\r\n')

      const anchor = document.createElement('a')
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(content)}`
      anchor.target = '_blank'
      anchor.download = 'contact-list.csv'
      anchor.click()
    },
    selectAction() {
      const self = this
      if (this.selectedAction === 'delete') {
        this.$refs.confirm
          .open(
            this.idlist
              ? this.$t('delete')
              : this.selectFilter.name === 'Archived' || this.selectFilter.name === 'Closed'
              ? this.$t('unarchived')
              : this.$t('archive'),
            `${
              this.idlist
                ? this.$t('deleteConfirmation')
                : this.selectFilter && (this.selectFilter.name === 'Archived' || this.selectFilter.name === 'Closed')
                ? this.$t('unarchivedConfirmation')
                : this.$t('archivedConfirmation')
            } ${this.selectedTableData.length} ${this.$t('contacts.contact')}  ?`,
            { color: 'red' },
            this.idlist
              ? this.$t('delete')
              : (this.selectFilter && this.selectFilter.name === 'Archived') || this.selectFilter.name === 'Closed'
              ? this.$t('unarchived')
              : this.$t('archive'),
          )
          .then(async agree => {
            if (agree) {
              self.loading = true
              let status = false
              self.selectedTableData.forEach(data => {
                const idx = this.contactListTable.findIndex(x => x._uid === data._uid)
                if (idx > -1) {
                  this.contactListTable.splice(idx, 1)
                  status = true
                }

                // if (this.idlist) {
                //   x._deleted = true
                // } else if (
                //   this.selectFilter &&
                //   (this.selectFilter.name === 'Archived' || this.selectFilter.name === 'Closed')
                // ) {
                //   x.archived = 0
                // } else {
                //   x.archived = 1
                // }
              })
              if (status) {
                this.currentList.contacts = this.contactListTable

                // this.updateContactList(this.currentList, async success => {
                //   if (success && success.ok) {
                //     await this.loadTable()
                //   }
                //   this.loading = false
                // })

                await this.$store.dispatch('global/insertOrUpdateContactList', {
                  sub_id: this.user.sub_id,
                  token: this.user.token,
                  ...this.currentList,
                })
                await this.loadTable()
              }

              // await self.bulkUpdateContact(self.selectedTableData, async success => {
              //   if (success) {
              //     this.replicateFromPouchDbGlobal(process.env.VUE_APP_DB_CONTACTS_NAME, this.user, async info => {
              //       await this.loadTable()
              //     })
              //   }
              //   self.loading = false
              // })
              this.selectedAction = ''
              this.selectedTableData = []
            } else {
              this.selectedAction = ''
            }
          })
      } else if (this.selectedAction === 'edit') {
        this.$refs.dialogBatchEdit.open(this.columnsAdditionalEdit).then(async dialog => {
          if (dialog.status) {
            self.loading = true
            this.selectedTableData.forEach(async data => {
              data.updated_at = this.$moment().valueOf()
              dialog.listEdit.forEach(editedData => {
                const field = this.columnsAdditionalEdit.find(x => x.id === editedData.field)
                if (field) data[field.value] = editedData.value
              })

              // await self.updateContact(data, async success => {
              //   if (success) {
              //     this.replicateFromPouchDbGlobal(process.env.VUE_APP_DB_CONTACTS_NAME, this.user, async info => {
              //       await this.loadTable()
              //     })
              //     this.selectedAction = ''
              //   } else {
              //     this.showAlert(this.$t('warningfailedsavedata'))
              //   }
              //   self.loading = false
              // })

              self.dialogContact = false
            })
          }

          this.currentList.contacts = this.contactListTable

          // this.updateContactList(this.currentList, async success => {
          //   if (success && success.ok) {
          //     await this.loadTable()
          //   }
          //   this.loading = false
          // })

          await this.$store.dispatch('global/insertOrUpdateContactList', {
            sub_id: this.user.sub_id,
            token: this.user.token,
            ...this.currentList,
          })
          await this.loadTable()
          this.loading = false
          this.selectedAction = ''
          this.selectedTableData = []
        })
      } else if (this.selectedAction === 'exportVcf') {
        this.exportVcf().then(res => {
          if (res) {
            this.selectedTableData = []
          }
          this.selectedAction = ''
        })
      } else if (this.selectedAction === 'label') {
        this.$refs.selectLabel.open(this.selectedTableData, this.labelData, this.labelValue).then(res => {
          if (res && res.status) {
            this.selectedTableData = []
          }
          this.selectedAction = ''
        })
      } else if (this.selectedAction === 'assign') {
        this.$refs.dialogSelectOperator.open(this.userList).then(async result => {
          if (result.status && result.data) {
            self.loading = true
            for (const data of this.selectedTableData) {
              data.assign_to = {
                email: result.data.value ? result.data.value.email : result.data.email ? result.data.email : '',
                image: '',
                name: '',
                phone_number: '',
                prof_pic: '',
              }
            }
            await self.bulkUpdateContact(self.selectedTableData, async success => {
              if (success) {
                this.replicateFromPouchDbGlobal(process.env.VUE_APP_DB_CONTACTS_NAME, this.user, async info => {
                  await this.loadTable()
                })
              }
              self.loading = false
            })
            this.selectedTableData = []
          }
          this.selectedAction = ''
        })
        this.selectedAction = ''
      } else if (this.selectedAction === 'send') {
        this.addBroadcastDialog = true
        this.newBroadcast += 1
      } else if (this.selectedAction === 'add') {
        this.$refs.dialogSelectList.open().then(async result => {
          if (result.status && result.data) {
            await this.addContactToList(this.selectedTableData, result.data, this.listContactValue, async status => {
              // if (status === false) {
              //   this.showAlert(this.$t('warningfailedsavedata'))
              // } else {
              //   this.replicateFromPouchDbGlobal(process.env.VUE_APP_DB_CONTACTS_NAME, this.user, async info => {
              //     await this.loadTable()
              //   })
              // }
              await this.loadTable()
              this.loading = false
            })
            this.selectedTableData = []
          } else {
            this.selectedAction = ''
          }
        })
      }
    },
    async saveContact() {
      // //console.log(this.model)
      if (!this.model.contactName) {
        this.errMessage = 'Invalid Input Name'
        this.showSnackbarErr = true
      } else if (!this.model.phoneNumber) {
        this.errMessage = 'Invalid Input Phone Number'
        this.showSnackbarErr = true

        // } else if (!this.model.nickname) {
        //   this.errMessage = 'Invalid Input Nickname'
        //   this.showSnackbarErr = true
      } else {
        this.dialogContact = false
        this.loading = true
        const res = await this.mappingContactList(
          this.model,
          this.columnsAdditional,
          this.isEdit,
          this.contactListTable,
          this.couchAllContact,
        )
        if (res.error) {
          this.showAlert(res.errorMessage)
          this.loading = false
        } else {
          this.currentList.contacts = res.data

          await this.$store.dispatch('global/insertOrUpdateContactList', {
            sub_id: this.user.sub_id,
            token: this.user.token,
            ...this.currentList,
          })
          await this.loadTable()
          this.loading = false
        }
      }
    },
    openNewContact() {
      // if (this.totalContact + 1 > this.user.max_contact) {
      //   this.alertText = this.$t('warningmaxcontacts')
      //   this.dialogAlert = true

      //   return
      // }
      this.isEdit = false
      this.model = {
        contactName: '',
        phoneNumber: '',
        created_at: Date.now(),
        data: [],
        notes: [],
      }
      this.columnsAdditional.forEach((x, index) => {
        this.model.data.push({
          index,
          attributeLabel: x.label,
          attributeSelected: x.text,
          attributeValue: x.defaultValue,
        })
      })
      this.dialogContact = true
    },
    openEditContact(data) {
      this.isEdit = true
      this.model = data
      this.model.contactName = data.name
      this.model.phoneNumber = data.phone_number
      this.model.data = []
      this.columnsAdditional.forEach((x, index) => {
        this.model.data.push({
          index,
          attributeLabel: x.label,
          attributeSelected: x.text,
          attributeValue: data[x.value],
        })
      })
      this.dialogContact = true
    },
    async deleteContact(data) {
      this.$refs.confirm
        .open(
          this.idlist
            ? this.$t('delete')
            : this.selectFilter.name === 'Archived' || this.selectFilter.name === 'Closed'
            ? this.$t('unarchived')
            : this.$t('archive'),
          `${
            this.idlist
              ? this.$t('deleteConfirmation')
              : this.selectFilter && (this.selectFilter.name === 'Archived' || this.selectFilter.name === 'Closed')
              ? this.$t('unarchivedConfirmation')
              : this.$t('archivedConfirmation')
          }  ${data.name} ?`,
          { color: 'red' },
          this.idlist
            ? this.$t('delete')
            : this.selectFilter && (this.selectFilter.name === 'Archived' || this.selectFilter.name === 'Closed')
            ? this.$t('unarchived')
            : this.$t('archive'),
        )
        .then(async confirm => {
          if (confirm) {
            this.loading = true
            const idx = this.currentList.contacts.findIndex(x => x._uid === data._uid)
            if (idx > -1) {
              this.currentList.contacts.splice(idx, 1)

              await this.$store.dispatch('global/insertOrUpdateContactList', {
                sub_id: this.user.sub_id,
                token: this.user.token,
                ...this.currentList,
              })
              await this.loadTable()

              // this.updateContactList(this.currentList, async success => {
              //   if (success && success.ok) {
              //     await this.loadTable()
              //   }
              //   this.loading = false
              // })
            }
            this.loading = false
          }
        })
    },
    showAlert(warningText) {
      this.alertText = warningText
      this.dialogAlert = true
    },

    // async saveConfig() {
    // this.dialogConfig = false

    // if (this.surveyConfig.status) {
    //   const selectedFields = this.listDraggable.filter(column => column.selected).map(col => col.name)

    //   // //console.log(selectedFields, this.surveyConfig, `INI SELECTED`)

    //   const updated = await this.updateEFormFields({ id: this.surveyConfig.id, selectedFields })
    //   if (!updated.status) {
    //     this.dialogConfig = true
    //     this.showAlert(updated.message)
    //   } else {
    //     this.listDraggable = this.listDraggable.filter(col => col.selected === undefined)
    //   }
    // }

    // // //console.log(this.listDraggable)

    // // //console.log(this.tableColumns)
    // this.loading = true

    // const res = await this.saveConfigToDb(this.listDraggable, this.tableColumns)

    // if (res.error) {
    //   this.dialogConfig = true
    //   this.showAlert(res.errorMessage)
    // } else {
    //   await this.loadSetting()
    //   this.tableColumns = res.data
    //   this.dialogConfig = false
    // }
    // this.loading = false
    // this.surveyConfig = { status: false, id: '' }
    // },
    async saveAttribute(data) {
      this.columnsSetting.value = data

      await this.updateSettings(this.columnsSetting)
    },
    addNewColumn() {
      // window.scrollTo({
      //   top: 100,
      //   left: 0,
      //   behavior: 'smooth',
      // })
      this.listDraggable.push({
        name: '',
        align: 'center',
        label: '',
        field: '',
        value: '',
        text: '',
        sortable: true,
        type: 'text',
        show: true,
        showable: true,
        formatDate: '',
        deleteable: true,
        isRequired: false,
        defaultValue: '',
        editable: true,
        order_number: this.tableColumns.length + 1,
      })
      setTimeout(() => {
        document.getElementById('yourScrollElementId').scrollTop = 999999
      }, 200)
    },
    getMessageStatusResult(status) {
      if (!status || status === 0) {
        return 'Unsend'
      }
      if (status === 1) {
        return 'Sent'
      }
      if (status === 2) {
        return 'Received'
      }
      if (status === 3) {
        return 'Read'
      }

      return 'Replied'
    },
    getEngagementName(level) {
      if (!level) return 'No Interaction'
      if (level === 0) {
        return 'No Interaction'
      }
      if (level === 1) {
        return 'Very Low'
      }
      if (level === 2) {
        return 'Low'
      }
      if (level === 3) {
        return 'Medium'
      }
      if (level === 4) {
        return 'High'
      }

      return 'Very High'
    },
    getProfPic(img, item) {
      // //console.log(img, item, `INI IMG`)

      // if (img) {
      //   https://https//bailey.superwa.io//ppic/skilffukl-v0u7ol8u/6281808870857/a.jpg
      // }
      // //console.log(img.toString().replace('https//', ''), `KE DETECT`)
      // if (img) {
      //   return img.replace('https//', '')
      // }

      // https://bailey.superwa.io/ppic/sae7d969w-sd9bpkyh5/6283114227556/a.jpg
      // //console.log(`${process.env.VUE_APP_WA_SERVICES_URL}ppic/${item.roomId}/${item.phone_number}/a.jpg`, `INI PICTURE`)
      if (item.hasOwnProperty('photoProfile')) {
        return item.photoProfile
      }

      return `${process.env.VUE_APP_WA_SERVICES_URL}ppic/${this.user.sub_id}-${item.instance_id}/${item.phone_number}/a.jpg`
    },
    dateFormater(dt) {
      return TimeFormater.dateFormater(dt)
    },
    gotoInbox(data) {
      if (data.roomId) {
        this.$router.push({
          name: 'inbox',
          params: {
            data,
          },
        })
      }
    },

    // async openConfig() {
    //   const res = await this.getEFormByContactList({ contactList: this.$route.params.idlist })
    //   let configTemp = []
    //   if (res.data) {
    //     this.surveyConfig.id = res.data._id
    //     let fields = []
    //     res.data.survey.pages.forEach(el => {
    //       el.elements.forEach((item, index) => {
    //         // //console.log(item, index)
    //         if (res.data.selectedFields.length === 0) {
    //           fields.push({
    //             align: 'left',
    //             defaultValue: '',
    //             deleteable: false,
    //             editable: false,
    //             field: item.title,
    //             formatDate: '',
    //             id: 100 + index,
    //             isRequired: true,
    //             label: item.title,
    //             name: item.title,
    //             order_number: 100 + index,
    //             show: true,
    //             showable: false,
    //             sortable: false,
    //             text: item.title,
    //             type: item.type,
    //             value: item.title,
    //             selected: true,
    //           })
    //         } else {
    //           fields.push({
    //             align: 'left',
    //             defaultValue: '',
    //             deleteable: false,
    //             editable: false,
    //             field: item.title,
    //             formatDate: '',
    //             id: 100 + index,
    //             isRequired: true,
    //             label: item.title,
    //             name: item.title,
    //             order_number: 100 + index,
    //             show: true,
    //             showable: false,
    //             sortable: false,
    //             text: item.title,
    //             type: item.type,
    //             value: item.title,
    //             selected: !!res.data.selectedFields.includes(item.title),
    //           })
    //         }
    //       })
    //     })
    //     fields = _uniq(fields)
    //     configTemp = [...fields]
    //     this.surveyConfig.status = true
    //   }
    //   // //console.log(this.columnsEditable, 'data');
    //   for (const column of this.columnsEditable) {
    //     // //console.log(column, `INI COLUMN`)
    //     configTemp.push(column)
    //   }

    //   this.listDraggable = configTemp.filter(x => x).sort((a, b) => (a.order_number < b.order_number ? -1 : 0))
    //   this.dialogConfig = true
    // },
    async openConfig2() {
      // this.$router.push({ name: 'contacts-list-custom-config', params: { idlist: this.$route.params.idlist }})
      this.$refs.dialogConfigFormBuilder.open().then(result => {
        if (result.status) {
          this.loadTable()
        } else {
          this.showAlert(result.error)
        }
      })
    },
    async loadTable() {
      if (this.idlist) {
        // await this.loadContactListContentById(this.idlist)

        const res = await this.$store.dispatch('global/getContactLists', {
          sub_id: this.user.sub_id,
          token: this.user.token,
        })
        if (res.status) {
          this.listContactValue = res.data
          this.currentList = res.data.find(x => x.list_id === this.idlist)
          this.contactListTable = this.currentList.contacts
          this.contactListTableOrigin = this.currentList.contacts

          for (const list of res.data) {
            // //console.log(list)
            this.totalContact += list.contacts.length
          }
          let filteredObjColumn = this.currentList.columns
          filteredObjColumn = this.selectionSort(filteredObjColumn)

          this.tableColumns = filteredObjColumn
        }
      }
    },
    async loadSetting() {
      // await this.loadSettings()
      // const obj = await this.mappingSettingsContacts(this.idlist, this.allSettings)

      // this.columnsSetting = obj.columnsSetting

      // this.tableColumns = filteredObjColumn
      this.itemsBreadcrumbs = [
        {
          text: 'Contact',
          disabled: false,
          exact: true,
          link: true,
          to: {
            name: 'contacts-group-list',
          },
        },
      ]

      // this.labelData = obj.labelData
      // this.labelSetting = obj.labelSetting
      // this.labelValue = obj.labelValue
      // this.settingFilterSetting = obj.settingFilterSetting
      // this.itemsFilter = obj.itemsFilter
      const contactList = await this.$store.dispatch('eOrdering/getContactListCheck', {
        contact: this.$route.params.idlist,
      })
      if (contactList.status) {
        this.itemsBreadcrumbs.push({
          text: contactList.data.list_name,
          disabled: true,

          // link: true,
          // exact: true,
          // to: {
          //   name: 'contacts-group-list',
          // },
        })
      }
      const labelsPg = await this.$store.dispatch('global/getSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'label',
      })

      if (labelsPg.status) {
        this.labelData = labelsPg.data.value
      }
      const labelsValuePg = await this.$store.dispatch('global/getSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'label_value',
      })

      if (labelsValuePg.status) {
        this.labelValue = labelsValuePg.data.value
      }
    },
    setIsConfig() {
      if (this.currentList.fromSystem !== null) {
        this.isConfig = this.currentList.fromSystem
      } else {
        this.isConfig = this.tableColumns.some(
          col =>
            col.deleteable === false &&
            col.editable === false &&
            (col.name === 'order_count' ||
              col.field === 'order_count' ||
              col.name === 'total_order' ||
              col.field === 'total_order' ||
              col.name === 'last_order_time' ||
              col.field === 'last_order_time' ||
              col.name === 'code' ||
              col.field === 'code' ||
              col.name === 'Code Name' ||
              col.field === 'code_name' ||
              col.name === 'answerData' ||
              col.field === 'answerData'),
        )
      }
    },
    async verifyDataVerification(phoneNumber) {
      const { status, message } = await this.$store.dispatch('happyhome/verifyAccountUserHappyhome', phoneNumber)
      this.alertMessage = message
      this.seeAlertMessage = true
      if (status) {
        this.loadTable()
      }
      setTimeout(() => {
        this.seeAlertMessage = false
      }, 2000)
    },
    async rejectDataVerification() {
      // //console.log(this.currentPhoneNumber, 'data')
      // //console.log(this.reasonRejected, 'data')
      const { status, message } = await this.$store.dispatch('happyhome/rejectDataVerificationAccount', {
        phoneNumber: this.currentPhoneNumber,
        reasonRejected: this.reasonRejected,
      })
      this.alertMessage = message
      this.seeAlertMessage = true
      if (status) {
        this.loadTable()
      }

      this.reasonRejected = ''
    },
  },
}
</script>
<style scoped>
.height-dialog {
  max-height: 400px;
  overflow-y: auto;
}
@media only screen and (max-width: 578px) {
  .sm-display {
    display: none;
  }

  .last-served-image {
    display: none;
  }

  .last-served {
    font-size: 12px;
  }

  .phone-number {
    font-size: 12px;
  }
}

@media only screen and (min-width: 578px) {
  .sm-display-mobile {
    display: none;
  }
}
.bottom-action {
  display: none;
}
@media (max-width: 536px) {
  .hide-buton {
    display: none;
  }
  .bottom-action {
    display: inherit;
  }
  .block-condition {
    display: flex;
    flex: 1 0 auto;
    min-width: 100% !important;
  }
}
.padding-hero {
  padding-left: 80px;
  padding-right: 80px;
}
.mobile-tag {
  display: none;
}
/* Mobile */

@media (max-width: 536px) {
  .hero-landing {
    justify-content: center;
    text-align: center;
  }
  .mobile-tag {
    display: block;
  }
  .hide-lottie {
    display: none;
  }
  /* .hero-subtitle {
    width: 200px;
  } */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}
</style>
