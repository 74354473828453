<template>
  <v-dialog v-model="dialogConfigFormBuilder">
    <div id="surveyCreator"></div>
  </v-dialog>
</template>

<script>
import * as SurveyCreator from 'survey-creator-knockout'
import * as SurveyKo from 'survey-core'
import 'survey-core/defaultV2.min.css'
import 'survey-creator-core/survey-creator-core.min.css'
import eFormMixin from '../../mixins/eFormMixin'
import contactMixin from '../../mixins/contactMixin'
import {
  mdiFormSelect,
  mdiInformationOutline,
  mdiPlus,
  mdiRadioTower,
  mdiCounter,
  mdiImageFilterCenterFocusWeak,
  mdiPoundBoxOutline,
  mdiPlusCircle,
  mdiDragVertical,
  mdiDeleteOutline,
} from '@mdi/js'
import { resolve } from 'path'

SurveyCreator.SurveyQuestionEditorDefinition.definition = {}

const creatorOptions = {
  showEmbeddedSurveyTab: false,
  showTestSurveyTab: false,
  showOptions: false,
  showLogicTab: false,
  showJSONEditorTab: false,
  questionTypes: [
    'text',
    'checkbox',
    'radiogroup',
    'dropdown',
    'comment',
    'rating',
    'imagepicker',
    'boolean',
    'html',
    'expression',
  ],
  //   pageEditMode: 'single',
  showTitlesInExpressions: false,
  allowEditExpressionsInTextEditor: true,
  //   showSurveyTitle: false,
}

export default {
  name: 'survey-creator',
  mixins: [eFormMixin, contactMixin],
  setup() {
    return {
      icons: {
        mdiFormSelect,
        mdiInformationOutline,
        mdiPlus,
        mdiRadioTower,
        mdiCounter,
        mdiImageFilterCenterFocusWeak,
        mdiPoundBoxOutline,
        mdiPlusCircle,
        mdiDragVertical,
        mdiDeleteOutline,
      },
    }
  },
  data() {
    return {
      idlist: '',
      columns: [],
      currentList: null,
      createSuccess: false,
      surveyCreator: '',
      itemsBreadcrumbs: [
        {
          text: 'Contact',
          disabled: false,
          link: true,
          exact: true,
          to: {
            name: 'contacts-group-list',
          },
        },
      ],
      maxVisibleIndex: 0,
      dialogConfigFormBuilder: false,
      currentSavedReplies: [],
      columnToSave: [],
      resolve: null,
      reject: null,
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
  },
  async mounted() {
    this.idlist = this.$route.params.idlist
  },
  methods: {
    async open() {
      this.dialogConfigFormBuilder = true
      this.loadDataColumn()
      this.getSavedReplies()

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    async loadDataColumn() {
      const self = this
      const { data } = await this.$store.dispatch('global/getContactLists', {
        sub_id: this.user.sub_id,
        token: this.user.token,
      })

      //ambil data column dari db atau defaultnya
      this.currentList = data.find(contact => contact.list_id === this.idlist)
      this.columns = this.currentList.columns.filter(column => column.name !== 'phone_number_show')

      const columnsDefault = {}
      columnsDefault.elements = this.columns.map(column => {
        column.title = column.text
        column.visible = column.show
        column.inputType = column.type
        return column
      })
      columnsDefault.name = 'default_column'
      columnsDefault.description = 'Field to show to the table'
      columnsDefault.title = 'Default Column'

      const { data: eForm } = await this.getEFormByContactList({ contactList: this.idlist })

      let columnToShow = {
        pages: [columnsDefault],
      }
      if (eForm)
        columnToShow = {
          pages: [columnsDefault, ...eForm.survey.pages],
          title: eForm.title,
          description: eForm.description,
        }
      this.surveyCreator = new SurveyCreator.SurveyCreator(creatorOptions)
      this.surveyCreator.text = JSON.stringify(columnToShow)

      this.surveyCreator.onElementAllowOperations.add(function (_, options) {
        const nm = options.obj?.name
        const ty = options.obj?.getType()
        const pg = options.obj?.page?.name

        //console.log(options.obj?.getType(), 'data');
        if (
          (pg === 'default_column' && nm === 'phone_number') ||
          nm === 'name' ||
          nm === 'created_by' ||
          nm === 'updated_by' ||
          nm === 'created_at' ||
          nm === 'updated_at'
        ) {
          options.allowDelete = false
          options.allowChangeType = false
          options.allowCopy = false
        } else {
          options.allowChangeType = false
          options.allowCopy = false
        }

        SurveyCreator.localization.getLocale('en').pe.defaultValue = 'Default Value'
        SurveyCreator.localization.getLocale('en').pe.title = 'Column Name'
        SurveyCreator.localization.getLocale('en').ed.addNewQuestion = 'Add New Column'
        if (ty === 'page' && nm === 'default_column') {
          SurveyCreator.localization.getLocale('en').ed.addNewQuestion = 'Add New Column'
        } else {
          SurveyCreator.localization.getLocale('en').ed.addNewQuestion = 'Add Question'
        }
      })

      this.surveyCreator.onShowingProperty.add(function (sender, options) {
        const column = options.obj?.name
        const property = options.property.name
        if (
          column === 'phone_number' ||
          column === 'name' ||
          column === 'created_by' ||
          column === 'updated_by' ||
          column === 'created_at' ||
          column === 'updated_at'
        ) {
          if (property === 'visible' || property === 'defaultValue' || property === 'required') {
            options.property.showProperty = true
            options.canShow = true
          } else {
            options.canShow = false
          }
        } else {
          if (
            property === 'visible' ||
            property === 'defaultValue' ||
            property === 'required' ||
            property === 'title'
          ) {
            options.property.showProperty = true
            options.canShow = true
          } else {
            options.canShow = false
          }
        }
      })

      this.surveyCreator.toolbarItems.push(
        new SurveyKo.Action({
          id: 'custom-save',
          visible: true,
          title: 'Save Config',
          action: function () {
            self.saveConfig()
          },
        }),
      )
      this.surveyCreator.toolbarItems.push(
        new SurveyKo.Action({
          id: 'cancel',
          visible: true,
          title: 'Cancel',
          action: function () {
            self.closeDialogConfig()
          },
        }),
      )
      this.surveyCreator.render('surveyCreator')
    },
    closeDialogConfig() {
      this.dialogConfigFormBuilder = false
    },
    async saveConfig() {
      this.loading = true

      this.columnToSave = this.surveyCreator.survey.pages[0].elements.map(column => {
        const findColumn = this.columns.find(col => column.name === col.name)
        if (!findColumn) {
          const columnName = column.title.trim().replace(/\s\s+/g, '_').toLowerCase()
          return {
            name: columnName,
            show: column.visible === undefined || column.visible ? true : column.visible,
            text: column.title,
            type: 'text',
            align: 'left',
            field: columnName,
            label: column.title,
            value: columnName,
            editable: true,
            showable: true,
            sortable: true,
            deleteable: true,
            formatDate: '',
            isRequired: !column.isRequired ? false : true,
            defaultValue: column.defaultValue ? column.defaultValue : '',
            order_number: this.columns.length + 1,
          }
        } else {
          if (!findColumn.editable && !findColumn.deleteable) {
            findColumn.show = column.visible === undefined || column.visible ? true : column.visible
            findColumn.isRequired = !column.isRequired ? false : true
            return findColumn
          } else {
            findColumn.show = column.visible === undefined || column.visible ? true : column.visible
            findColumn.text = column.title
            findColumn.defaultValue = column.defaultValue ? column.defaultValue : ''
            findColumn.isRequired = !column.isRequired ? false : true
            return findColumn
          }
        }
      })

      this.saveSurvey(JSON.parse(this.surveyCreator.text))
      this.loading = false
    },
    async saveSurvey(survey) {
      // //console.log(survey, 'data')
      // const dataForm = this.$store.getters['link/getSaveDataEform'];
      const surveyPagesFiltered = survey.pages.filter(page => page.name !== 'default_column')
      survey.pages = surveyPagesFiltered
      survey.pages.forEach(page => {
        page.name = page.title.trim().replace(/\s\s+/g, '_').toLowerCase()
      })
      // //console.log(surveyPagesFiltered, 'data')
      const { data: eForm } = await this.getEFormByContactList({ contactList: this.idlist })
      // //console.log(eForm, 'data');
      const data = {
        title: survey.title ? survey.title : eForm ? eForm.title : '',
        description: survey.description,
        facebook_pixel_id: eForm ? eForm.facebook_pixel_id : '',
        google_tag_manager_id: eForm ? eForm.google_tag_manager_id : '',
        eform_id: eForm ? eForm._id : this.user.sub_id + '-' + this.$nanoid(),
        contactList: this.idlist,
        addToSurvey: eForm ? eForm.addToSurvey : false,
        addToQuickReplayMenu: eForm ? eForm.addToQuickReplayMenu : true,
        isFromCustomerGroup: true,
        selectedFields: eForm ? eForm.selectedFields : [],
        channel: eForm ? eForm.channel : '',
        survey,
      }

      let result = null

      if (!eForm && surveyPagesFiltered.length > 0) {
        result = await this.saveConfigToDb(this.columnToSave, this.columns, true)
      } else {
        result = await this.saveConfigToDb(this.columnToSave, this.columns, false)
        if (!surveyPagesFiltered.length) {
          this.resolve({
            status: true,
          })
        }
      }

      if (!result.error && surveyPagesFiltered.length > 0) {
        if (!data.title) {
          this.resolve({
            status: false,
            error: 'Title of survey is required',
          })
        } else {
          await this.editEForm(data)
          const self = this

          if (this.createSuccess) {
            if (data.addToQuickReplayMenu) {
              const indexAddQuickMenu = this.currentSavedReplies.findIndex(
                savedReply => savedReply.eFormId === data.eform_id && savedReply.tag === 'Add E-Form',
              )
              const indexSendQuickMenu = this.currentSavedReplies.findIndex(
                savedReply => savedReply.eFormId === data.eform_id && savedReply.tag === 'Send E-Form',
              )
              if (indexAddQuickMenu > 0 && indexSendQuickMenu > 0) {
                this.currentSavedReplies[indexAddQuickMenu] = {
                  tag: 'Add E-Form',
                  showText: `Add this contact to ${data.title}`,
                  text: 'add_eform',
                  isEform: true,
                  eFormId: data.eform_id,
                  listId: self.idlist,
                  channelId: '',
                }
                this.currentSavedReplies[indexSendQuickMenu] = {
                  tag: 'Send E-Form',
                  showText: `Send ${data.title} form to this contact`,
                  text: `https://enterprise.marketa.id/e-form/survey/${data.eform_id}`,
                  isEform: true,
                  eFormId: data.eform_id,
                  channelId: '',
                }
              } else {
                this.currentSavedReplies.unshift({
                  id: this.$nanoid(),
                  tag: 'Add E-Form',
                  showText: `Add this contact to ${data.title}`,
                  text: 'add_eform',
                  isEform: true,
                  eFormId: data.eform_id,
                  listId: self.idlist,
                  channelId: '',
                })
                this.currentSavedReplies.unshift({
                  id: this.$nanoid(),
                  tag: 'Send E-Form',
                  showText: `Send ${data.title} form to this contact`,
                  text: `https://enterprise.marketa.id/e-form/survey/${data.eform_id}`,
                  isEform: true,
                  eFormId: data.eform_id,
                  channelId: '',
                })
              }
              await this.$store.dispatch('global/updateSettingsByKey', {
                sub_id: this.user.sub_id,
                token: this.user.token,
                key: 'saved_replies',
                value: this.currentSavedReplies,
              })
            }
            self.resolve({
              status: true,
            })
          }
        }
      }
        this.dialogConfigFormBuilder = false
    },
    async getSavedReplies() {
      const { data } = await this.$store.dispatch('global/getSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'saved_replies',
      })
      this.currentSavedReplies = data.value
    },
  },
}
</script>
<style>
#surveyCreator {
  height: 100vh;
}
.svc-creator .svc-full-container .svc-creator__banner {
  display: none !important;
}
</style>
